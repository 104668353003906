
import { computed, defineComponent, PropType } from 'vue'
import { PPTShapeElement, ShapeText } from '@/types/slides'
import useElementOutline from '@/views/components/element/hooks/useElementOutline'
import useElementShadow from '@/views/components/element/hooks/useElementShadow'
import useElementFlip from '@/views/components/element/hooks/useElementFlip'

import GradientDefs from './GradientDefs.vue'

export default defineComponent({
	name: 'base-element-shape',
	components: {
		GradientDefs
	},
	props: {
		elementInfo: {
			type: Object as PropType<PPTShapeElement>,
			required: true
		}
	},
	setup(props) {
		const outline = computed(() => props.elementInfo.outline)
		const { outlineWidth, outlineStyle, outlineColor } = useElementOutline(
			outline
		)

		const shadow = computed(() => props.elementInfo.shadow)
		const { shadowStyle } = useElementShadow(shadow)

		const flipH = computed(() => props.elementInfo.flipH)
		const flipV = computed(() => props.elementInfo.flipV)
		const { flipStyle } = useElementFlip(flipH, flipV)

		const text = computed<ShapeText>(() => {
			const defaultText: ShapeText = {
				content: '',
				defaultFontName: 'Sans-serif',
				defaultColor: '#000',
				align: 'middle'
			}
			if (!props.elementInfo.text) return defaultText

			return props.elementInfo.text
		})

		const viewBox = computed(() =>
			Array.isArray(props.elementInfo.viewBox)
				? props.elementInfo.viewBox
				: [props.elementInfo.viewBox, props.elementInfo.viewBox]
		)
		return {
			shadowStyle,
			outlineWidth,
			outlineStyle,
			outlineColor,
			flipStyle,
			text,
			viewBox
		}
	}
})
