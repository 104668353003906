
import {
	computed,
	defineComponent,
	onBeforeUnmount,
	onMounted,
	PropType,
	ref
} from 'vue'
import { ElfElementTypes, PPTElfElement } from '@/types/slides'
import { toCameCaseObj } from '@/utils/common'
import microAppPools from './microAppPools'
import emitter, { EmitterEvents } from '@/utils/emitter'
import useAndroid from '@/hooks/useAndroid'

export default defineComponent({
	name: 'microAppEle',
	props: {
		elementInfo: {
			type: Object as PropType<PPTElfElement>,
			required: true
		}
	},
	setup(props) {
		const { checkJsBridge } = useAndroid()

		const elementInfo = computed(() => props.elementInfo)
		const elId = computed(() => elementInfo.value.id)
		const micAppData = computed(() => {
			const data = toCameCaseObj((elementInfo.value as any)._meta || {})
			if (elementInfo.value.subtype === ElfElementTypes.SING_PLAY) {
				return {
					song: data,
					playerOptions: {}
				}
			}
			return data
		})
		const microApp = computed(() => {
			return microAppPools[elementInfo.value.subtype]
		})

		const compAction = ref<any>()
		const doCompAction = () => {
			if (elementInfo.value.subtype === ElfElementTypes.SING_PLAY) {
				compAction.value = {
					timeStamp: new Date().getTime(),
					type: 'stop'
				}
			}
		}

		const platform = computed(() => {
			return checkJsBridge.value ? 'android' : 'pc'
		})

		onMounted(() => {
			emitter.on(EmitterEvents.SWITCH_SLIDE_PAGE, doCompAction)
			emitter.on(EmitterEvents.PPT_STOP_PLAY, doCompAction)
		})

		onBeforeUnmount(() => {
			emitter.off(EmitterEvents.SWITCH_SLIDE_PAGE)
			emitter.off(EmitterEvents.PPT_STOP_PLAY)
		})
		return {
			elId,
			micAppData,
			microApp,
			compAction,
			platform
		}
	}
})
