
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'playback-controls',
  props: {
    isPlay: {
      type: Boolean,
      default: false,
    },
    isActive: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
})
