// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/ensemble-practice/chacha.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".relate-students-dialog[data-v-652d249a]{padding:25px 30px;font-size:16px;width:100%;height:300px;position:relative}.relate-students-dialog .close-tool-tip[data-v-652d249a]{position:absolute;width:22px;height:22px;top:5px;right:25px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:100% 100%;cursor:pointer}.relate-students-dialog .content[data-v-652d249a]{height:150px;padding-top:20px;font-size:30px;color:#764b19;display:flex;justify-content:center;align-items:center}.relate-students-dialog .btns[data-v-652d249a]{font-size:30px;height:140px;padding:0 20px;align-items:center;display:flex;justify-content:space-between;font-weight:700}.relate-students-dialog .btns .ok[data-v-652d249a]{text-shadow:2px 2px 12px #449aea;-webkit-text-stroke:1.2px #449aea;background-image:linear-gradient(0deg,#33a1ff,#5bbbff),linear-gradient(#ff850d,#ff850d);background-blend-mode:normal,normal}.relate-students-dialog .btns .cancel[data-v-652d249a],.relate-students-dialog .btns .ok[data-v-652d249a]{cursor:pointer;width:230px;height:72px;border-radius:2.2rem;text-align:center;line-height:40px;font-family:SourceHanSansCN-Regular;font-stretch:normal;color:#fff;display:flex;align-items:center;justify-content:center}.relate-students-dialog .btns .cancel[data-v-652d249a]{text-shadow:2px 2px 12px #0cc187;-webkit-text-stroke:1.2px #0cc187;background-image:linear-gradient(0deg,#00c88d,#12df9a)}.relate-students-dialog .btns .ok[data-v-652d249a]:hover{background-image:linear-gradient(0deg,#217cff,#3d9dff)}.relate-students-dialog .btns .cancel[data-v-652d249a]:hover{background-image:linear-gradient(0deg,#00bc7a,#0ed888)}", ""]);
// Exports
module.exports = exports;
