
import { MutationTypes, useStore } from "@/store";
import { PPTElfAudioElement } from "@/types/slides";
import { computed, defineComponent, watchEffect, ref, watch } from "vue";
import useLocalCDN from "@/hooks/useLocalCDN";
import UploadProgress from "@/components/UploadProgress.vue";
import useHistorySnapshot from "@/hooks/useHistorySnapshot";
import { UpLoadReply } from "@/hooks/useUpLoadResource";
import { millisecondsFormat } from "@/utils/formatTime";
import _ from "lodash";

export default defineComponent({
  name: "audio-style-panel",
  components: {
    UploadProgress,
  },
  setup() {
    const store = useStore();
    const { addHistorySnapshot } = useHistorySnapshot();
    const handleElement = computed<PPTElfAudioElement>(
      () => store.getters.handleElement
    );

    const { getFileNameFromCDNUrl } = useLocalCDN();
    const fileName = ref<string>("");
    const visible = ref<boolean>(false);
    const file = ref<File>();
    const nextPlay = ref(
      handleElement.value?.nextStepEvents?.includes("play") || false
    );
    const clipPoints = ref([
      handleElement.value.clipStart || 0,
      handleElement.value.clipEnd || handleElement.value.duration || 0,
    ]);
    const clipLimitPoints = computed(() => [
      0,
      handleElement.value.duration || 0,
    ]);
    const clipPointsFormat = computed(() =>
      clipPoints.value.map((time) => {
        return millisecondsFormat(time as number);
      })
    );
    const updateNextStepEvents = (value: boolean, status: any) => {
      if (value) {
        if (Array.isArray(handleElement.value.nextStepEvents)) {
          handleElement.value.nextStepEvents.push(status);
        } else {
          handleElement.value.nextStepEvents = [status];
        }
      } else {
        // handleElement.value.nextStepEvents = handleElement.value.nextStepEvents.filter(
        // 	(item: any) => item !== status
        // )
        delete handleElement.value.nextStepEvents;
      }
      store.commit(MutationTypes.UPDATE_ELEMENT, {
        id: handleElement.value.id,
        props: {
          ...handleElement.value,
        },
      });
      addHistorySnapshot();
    };

    watchEffect(async () => {
      if (handleElement.value) {
        const name = await getFileNameFromCDNUrl(handleElement.value.src);
        fileName.value = name === "" ? "点击选择文件" : name;
      }
    });

    watch(
      handleElement,
      (newVal) => {
        if (!handleElement.value) {
          return
        }
        clipPoints.value = [
          newVal.clipStart || 0,
          newVal.clipEnd || handleElement.value.duration || 0,
        ];
      },
      { deep: true }
    );
    const handleChange = (files: File[]) => {
      const audioFile = files[0];
      if (!audioFile) return;
      file.value = audioFile;
      visible.value = true;
    };

    const handleOk = (res: UpLoadReply) => {
      const props = { src: res.url, md5: res.md5, size: res.size };
      visible.value = false;
      store.commit(MutationTypes.UPDATE_ELEMENT, {
        id: handleElement.value.id,
        props,
      });
      addHistorySnapshot();
    };

    const onClipPointChange = _.debounce((values: Array<number>) => {
      store.commit(MutationTypes.UPDATE_ELEMENT, {
        id: handleElement.value.id,
        props: {
          ...handleElement.value,
          clipStart: values[0],
          clipEnd: values[1],
        },
      });
      addHistorySnapshot();
    }, 1000);

    const handleCancel = () => {
      visible.value = false;
    };

    return {
      nextPlay,
      updateNextStepEvents,
      fileName,
      handleChange,
      visible,
      file,
      handleOk,
      handleCancel,
      clipPoints,
      clipLimitPoints,
      clipPointsFormat,
      onClipPointChange,
    };
  },
});
