const imageBg1Path = require('@/assets/images/question-toolbar/question-bg1.jpg')
const imageBg2Path = require('@/assets/images/question-toolbar/question-bg2.jpg')
const imageBg3Path = require('@/assets/images/question-toolbar/question-bg3.jpg')
const imageBg4Path = require('@/assets/images/question-toolbar/question-bg4.jpg')

export const imageBackgroundPathMap = {
  imageBg1: imageBg1Path,
  imageBg2: imageBg2Path,
  imageBg3: imageBg3Path,
  imageBg4: imageBg4Path
}

export const enum QuestionType {
	choiceQuestion = 1, // 单选
	multiPleQuestion = 2, // 多选
	sheetMusicCreation = 3, // 编创
	trueOrfalseQuestion = 4, // 判断题
	sheetMusicSongSing = 5, // 演唱
	sheetMusicSongPlay = 6, // 演奏
	sheetMusicSongRegard = 7, // 视唱
	connectQuestion = 8, // 连线
	fillsInQuestion = 9, // 填空
	briefQuestion = 10, // 简答
	netingQustion = 11, // 套题
	dragQuestion = 12, // 拖拽
	colorWheelQuestion = 13, // 色环
	jiasawQuestion = 14 // 拼图
}
export const noSupportQuestionType = [
  QuestionType.sheetMusicSongPlay,
  QuestionType.sheetMusicSongRegard,
  QuestionType.sheetMusicSongSing,
  QuestionType.colorWheelQuestion,
];
