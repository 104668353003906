import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-dee57118")
const _hoisted_1 = { class: "enjoy-song" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_slide_show = _resolveComponent("slide-show")!
  const _component_playback_controls = _resolveComponent("playback-controls")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.meta.picture_url.length === 0)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          style: _normalizeStyle(`background-size: ${_ctx.titleLen}px 100%;`),
          class: "title"
        }, [
          _createElementVNode("div", {
            style: _normalizeStyle(`width: ${_ctx.titleLen}px`),
            class: "title-content"
          }, _toDisplayString(_ctx.meta.name || ""), 5)
        ], 4))
      : _createCommentVNode("", true),
    _createVNode(_component_slide_show, {
      "image-list": _ctx.meta.picture_url,
      class: "slide-show-area"
    }, null, 8, ["image-list"]),
    _createVNode(_component_playback_controls, {
      ref: "playbackControlsRef",
      elementInfo: _ctx.elementInfo,
      class: "playback-controls-area"
    }, null, 8, ["elementInfo"])
  ]))
}