
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watchEffect,
  reactive,
  inject
} from "vue";
import { PPTImageElement } from "@/types/slides";
import useElementShadow from "@/views/components/element/hooks/useElementShadow";
import useElementFlip from "@/views/components/element/hooks/useElementFlip";
import useClipImage from "./useClipImage";
import useFilter from "./useFilter";
import useGesture from "../hooks/useGesture";
import useTurnPageSlide from "@/views/EvScreen/hooks/useTurnPageSlide";

import ImageOutline from "./ImageOutline/index.vue";

export default defineComponent({
  name: "base-element-image",
  components: {
    ImageOutline,
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTImageElement>,
      required: true,
    },
  },
  setup(props) {
    const shadow = computed(() => props.elementInfo.shadow);
    const { shadowStyle } = useElementShadow(shadow);
    const { nextStep } = useTurnPageSlide();
    const isDblClickLock = ref(false);

    const handleClickScreenElement:any = inject('handleClickScreenElement')

    const flipH = computed(() => props.elementInfo.flipH);
    const flipV = computed(() => props.elementInfo.flipV);
    const { flipStyle } = useElementFlip(flipH, flipV);

    const clip = computed(() => props.elementInfo.clip);
    const { clipShape, imgPosition } = useClipImage(clip);

    const filters = computed(() => props.elementInfo.filters);
    const { filter } = useFilter(filters);

    const showMask = ref(false);
    const scale = ref(1);

    const minScale = computed(() => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const imageWidth = props.elementInfo.width;
      const imageHeight = props.elementInfo.height;

      const widthScale = (windowWidth * 0.9) / imageWidth;
      const heightScale = (windowHeight * 0.9) / imageHeight;

      return Math.min(widthScale, heightScale, 1);
    });

    const resizeImage = () => {
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      const imageWidth = props.elementInfo.width;
      const imageHeight = props.elementInfo.height;

      const widthScale = (windowWidth * 0.9) / imageWidth;
      const heightScale = (windowHeight * 0.9) / imageHeight;

      return Math.min(widthScale, heightScale, 1);
    };

    // 图片双击预览图片
    const onImageDblClick = (e: any) => {
      e.stopPropagation();
      isDblClickLock.value = true;
      showMask.value = true;
      scale.value = resizeImage();
    };

    // 切换到下一页幻灯片
    const toNextSlide = () => {
      !isDblClickLock.value && nextStep();
    };

    // 图片单击，延迟200ms判断是否切换到下一页
    const onImageClick = (e: any) => {
      e.stopPropagation();
      isDblClickLock.value = false;
      setTimeout(toNextSlide, 200);
    };
    const handleClickBaseElfElement = (e:Event) => {
      handleClickScreenElement && handleClickScreenElement(e)
    }

    const closeMask = (event: MouseEvent) => {
      // 阻止事件冒泡
      event.stopPropagation();
      // 隐藏遮罩层
      showMask.value = false;
      // 重置缩放比例
      scale.value = 1;
    };

    const onImageWheel = (event: WheelEvent) => {
      event.preventDefault();
      event.stopPropagation();

      const deltaY = event.deltaY;
      const direction = deltaY > 0 ? -1 : 1;
      // Math.max(scale.value + zoomValue, 1)

      //   const newScale = Math.max(scale.value + direction * 0.1, 1);
      const newScale = Math.max(scale.value + direction * 0.1, minScale.value);
      scale.value = newScale;
    };

    let startScale = 1;
    const gestureDom = ref<HTMLElement | null>(null);

    const { initGestureEvent, destroyGestureEvent } = useGesture(gestureDom, {
      gesturestart: () => {
        startScale = scale.value;
      },
      gesturechange: (e) => {
        const newScale = Math.max(startScale * e.scale, 1);
        scale.value = newScale;
      },
    });

    watchEffect(() => {
      const gesture = gestureDom.value;
      if (gesture) initGestureEvent();
      else destroyGestureEvent();
    });

    // 新增放大缩小函数
    const zoomImage = (zoomValue: number) => {
      //   const newScale = Math.max(scale.value + zoomValue, 1);
      const newScale = Math.max(scale.value + zoomValue, minScale.value);
      scale.value = newScale;
    };

    const imageStyle = computed(() => ({
      transform: `translate(${dragData.imgPosX}px, ${dragData.imgPosY}px) scale(${scale.value})`,
      transition: ".1s",
    }));
    // pc端图片移动
    const dragData = reactive({
      dragging: false,
      startX: 0,
      startY: 0,
      imgPosX: 0,
      imgPosY: 0,
    });
    // const imageRefimageRef = ref(null);

    const handleMouseDown = (event: MouseEvent) => {
      event.stopPropagation();
      dragData.dragging = true;
      dragData.startX = event.clientX - dragData.imgPosX;
      dragData.startY = event.clientY - dragData.imgPosY;
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
      if (gestureDom.value !== null) {
        gestureDom.value.style.pointerEvents = "none";
      }
    };

    const handleMouseUp = (event: MouseEvent) => {
      event.stopPropagation();
      if (dragData.dragging) {
        dragData.dragging = false;
        window.removeEventListener("mousemove", handleMouseMove);
        window.removeEventListener("mouseup", handleMouseUp);
      }
      if (gestureDom.value !== null) {
        gestureDom.value.style.pointerEvents = "auto";
      }
    };

    const handleMouseMove = (event: MouseEvent) => {
      if (dragData.dragging) {
        const targetPosX = event.clientX - dragData.startX;
        const targetPosY = event.clientY - dragData.startY;
        if (
          Math.abs(targetPosX - dragData.imgPosX) > 1 ||
          Math.abs(targetPosY - dragData.imgPosY) > 1
        ) {
          dragData.imgPosX += (targetPosX - dragData.imgPosX) / 10;
          dragData.imgPosY += (targetPosY - dragData.imgPosY) / 10;
        }
      }
    };

    // 触摸控制图片移动
    // 触摸开始
    const touchStart = (event: TouchEvent) => {
      event.preventDefault(); // 防止默认滚动行为
      dragData.dragging = true;
      const touch = event.touches[0];
      dragData.startX = touch.clientX - dragData.imgPosX;
      dragData.startY = touch.clientY - dragData.imgPosY;
    };

    // 触摸移动
    const touchMove = (event: TouchEvent) => {
      event.preventDefault(); // 防止默认滚动行为
      if (dragData.dragging) {
        const touch = event.touches[0];
        const targetPosX = touch.clientX - dragData.startX;
        const targetPosY = touch.clientY - dragData.startY;
        if (
          Math.abs(targetPosX - dragData.imgPosX) > 1 ||
          Math.abs(targetPosY - dragData.imgPosY) > 1
        ) {
          dragData.imgPosX += (targetPosX - dragData.imgPosX) / 10;
          dragData.imgPosY += (targetPosY - dragData.imgPosY) / 10;
        }
      }
    };

    // 触摸结束
    const touchEnd = () => {
      dragData.dragging = false;
    };
    return {
      imgPosition,
      filter,
      flipStyle,
      shadowStyle,
      clipShape,
      onImageClick,
      handleClickBaseElfElement,
      onImageDblClick,
      closeMask,
      scale,
      onImageWheel,
      showMask,
      gestureDom,
      zoomImage,
      minScale,
      handleMouseDown,
      handleMouseUp,
      handleMouseMove,
      touchStart,
      touchEnd,
      touchMove,
      dragData,
      imageStyle,
    };
  },
});
