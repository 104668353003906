
import { PropType, defineComponent, computed } from 'vue'
import { PPTElement } from '@/types/slides'
import { useStore } from '@/store'

export default defineComponent({
	name: 'link-icon',
	props: {
		elementInfo: {
			type: Object as PropType<PPTElement>,
			required: true
		}
	},
	setup(props) {
		const store = useStore()
		const canvasScale = computed(() => store.state.canvasScale)
		const height = computed(() => {
			if (props.elementInfo.type === 'line') {
				return 0
			}
			return props.elementInfo.height
		})

		const rotate = computed(() => {
			if (
				props.elementInfo.type === 'text' ||
				props.elementInfo.type === 'image' ||
				props.elementInfo.type === 'shape'
			) {
				return props.elementInfo.rotate
			}
			return 0
		})
		return {
			canvasScale,
			rotate,
			height
		}
	}
})
