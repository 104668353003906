import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElementOutline = _resolveComponent("ElementOutline")!
  const _component_IconChartHistogram = _resolveComponent("IconChartHistogram")!
  const _component_IconChartHistogramOne = _resolveComponent("IconChartHistogramOne")!
  const _component_IconChartLine = _resolveComponent("IconChartLine")!
  const _component_IconChartLineArea = _resolveComponent("IconChartLineArea")!
  const _component_IconChartScatter = _resolveComponent("IconChartScatter")!
  const _component_IconChartPie = _resolveComponent("IconChartPie")!
  const _component_IconChartRing = _resolveComponent("IconChartRing")!

  return (_openBlock(), _createElementBlock("div", {
    class: "base-element-chart",
    style: _normalizeStyle({
      top: _ctx.elementInfo.top + 'px',
      left: _ctx.elementInfo.left + 'px',
      width: _ctx.elementInfo.width + 'px',
      height: _ctx.elementInfo.height + 'px',
    })
  }, [
    _createElementVNode("div", {
      class: "element-content",
      style: _normalizeStyle({
        backgroundColor: _ctx.elementInfo.fill,
      })
    }, [
      _createVNode(_component_ElementOutline, {
        width: _ctx.elementInfo.width,
        height: _ctx.elementInfo.height,
        outline: _ctx.elementInfo.outline
      }, null, 8, ["width", "height", "outline"]),
      (_ctx.chartType === 'bar')
        ? (_openBlock(), _createBlock(_component_IconChartHistogram, {
            key: 0,
            fill: _ctx.elementInfo.themeColor,
            strokeWidth: "2",
            size: _ctx.size
          }, null, 8, ["fill", "size"]))
        : (_ctx.chartType === 'horizontalBar')
          ? (_openBlock(), _createBlock(_component_IconChartHistogramOne, {
              key: 1,
              fill: _ctx.elementInfo.themeColor,
              strokeWidth: "2",
              size: _ctx.size
            }, null, 8, ["fill", "size"]))
          : (_ctx.chartType === 'line')
            ? (_openBlock(), _createBlock(_component_IconChartLine, {
                key: 2,
                fill: _ctx.elementInfo.themeColor,
                strokeWidth: "2",
                size: _ctx.size
              }, null, 8, ["fill", "size"]))
            : (_ctx.chartType === 'area')
              ? (_openBlock(), _createBlock(_component_IconChartLineArea, {
                  key: 3,
                  fill: _ctx.elementInfo.themeColor,
                  strokeWidth: "2",
                  size: _ctx.size
                }, null, 8, ["fill", "size"]))
              : (_ctx.chartType === 'scatter')
                ? (_openBlock(), _createBlock(_component_IconChartScatter, {
                    key: 4,
                    fill: _ctx.elementInfo.themeColor,
                    strokeWidth: "2",
                    size: _ctx.size
                  }, null, 8, ["fill", "size"]))
                : (_ctx.chartType === 'pie')
                  ? (_openBlock(), _createBlock(_component_IconChartPie, {
                      key: 5,
                      fill: _ctx.elementInfo.themeColor,
                      strokeWidth: "2",
                      size: _ctx.size
                    }, null, 8, ["fill", "size"]))
                  : (_ctx.chartType === 'ring')
                    ? (_openBlock(), _createBlock(_component_IconChartRing, {
                        key: 6,
                        fill: _ctx.elementInfo.themeColor,
                        strokeWidth: "2",
                        size: _ctx.size
                      }, null, 8, ["fill", "size"]))
                    : _createCommentVNode("", true)
    ], 4)
  ], 4))
}