
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { MutationTypes, useStore } from '@/store'
import { PPTShapeElement, ShapeText } from '@/types/slides'
import { ContextmenuItem } from '@/components/Contextmenu/types'
import useElementOutline from '@/views/components/element/hooks/useElementOutline'
import useElementShadow from '@/views/components/element/hooks/useElementShadow'
import useElementFlip from '@/views/components/element/hooks/useElementFlip'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

import GradientDefs from './GradientDefs.vue'
import ProsemirrorEditor from '@/views/components/element/ProsemirrorEditor.vue'

export default defineComponent({
	name: 'editable-element-shape',
	components: {
		GradientDefs,
		ProsemirrorEditor
	},
	props: {
		elementInfo: {
			type: Object as PropType<PPTShapeElement>,
			required: true
		},
		selectElement: {
			type: Function as PropType<
				(e: MouseEvent, element: PPTShapeElement, canMove?: boolean) => void
			>,
			required: true
		},
		contextmenus: {
			type: Function as PropType<() => ContextmenuItem[]>
		}
	},
	setup(props) {
		const store = useStore()

		const { addHistorySnapshot } = useHistorySnapshot()

		const handleSelectElement = (e: MouseEvent) => {
			if (props.elementInfo.lock) return
			e.stopPropagation()

			props.selectElement(e, props.elementInfo)
		}

		const outline = computed(() => props.elementInfo.outline)
		const { outlineWidth, outlineStyle, outlineColor } = useElementOutline(
			outline
		)

		const shadow = computed(() => props.elementInfo.shadow)
		const { shadowStyle } = useElementShadow(shadow)

		const flipH = computed(() => props.elementInfo.flipH)
		const flipV = computed(() => props.elementInfo.flipV)
		const { flipStyle } = useElementFlip(flipH, flipV)

		const editable = ref(false)

		const enterEditing = () => {
			editable.value = true
			store.commit(
				MutationTypes.SET_EDITING_SHAPE_ELEMENT_ID,
				props.elementInfo.id
			)
		}

		const exitEditing = () => {
			editable.value = false
			store.commit(MutationTypes.SET_EDITING_SHAPE_ELEMENT_ID, '')
		}

		const handleElementId = computed(() => store.state.handleElementId)
		watch(handleElementId, () => {
			if (handleElementId.value !== props.elementInfo.id) {
				if (editable.value) exitEditing()
			}
		})

		const text = computed<ShapeText>(() => {
			const defaultText: ShapeText = {
				content: '',
				defaultFontName: 'Sans-serif',
				defaultColor: '#000',
				align: 'middle'
			}
			if (!props.elementInfo.text) return defaultText

			return props.elementInfo.text
		})

		const updateText = (content: string) => {
			const _text = { ...text.value, content }
			store.commit(MutationTypes.UPDATE_ELEMENT, {
				id: props.elementInfo.id,
				props: { text: _text }
			})

			addHistorySnapshot()
		}
		const viewBox = computed(() =>
			Array.isArray(props.elementInfo.viewBox)
				? props.elementInfo.viewBox
				: [props.elementInfo.viewBox, props.elementInfo.viewBox]
		)

		return {
			shadowStyle,
			outlineWidth,
			outlineStyle,
			outlineColor,
			flipStyle,
			editable,
			text,
			handleSelectElement,
			updateText,
			enterEditing,
			viewBox
		}
	}
})
