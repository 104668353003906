import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-8d85e878")
const _hoisted_1 = ["d", "stroke", "stroke-width", "stroke-dasharray"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SvgWrapper = _resolveComponent("SvgWrapper")!

  return (_ctx.outline)
    ? (_openBlock(), _createBlock(_component_SvgWrapper, {
        key: 0,
        class: "image-polygon-outline",
        overflow: "visible",
        width: _ctx.width,
        height: _ctx.height
      }, {
        default: _withCtx(() => [
          _createElementVNode("path", {
            "vector-effect": "non-scaling-stroke",
            "stroke-linecap": "butt",
            "stroke-miterlimit": "8",
            "stroke-linejoin": "",
            fill: "transparent",
            d: _ctx.createPath(_ctx.width, _ctx.height),
            stroke: _ctx.outlineColor,
            "stroke-width": _ctx.outlineWidth,
            "stroke-dasharray": _ctx.outlineStyle === 'dashed' ? '10 6' : '0 0'
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["width", "height"]))
    : _createCommentVNode("", true)
}