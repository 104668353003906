
import { ElfElementTypes, PPTElfVideoElement, Slide } from '@/types/slides'
import {
    computed,
    defineComponent,
    onBeforeUnmount,
    onMounted,
    PropType,
    ref,
    watch
} from 'vue'
import { MutationTypes, useStore } from '@/store'
import emitter, { EmitterEvents } from '@/utils/emitter'
import xgplayer from '@/utils/xgplayer'
import logger from '@evideo/logger'
import Player from 'xgplayer'
import { videoSnapshotOssProcessSuffix } from '@/utils/image'

const defaultPoster = require('@/assets/images/video-control/poster.jpg')

export default defineComponent({
    name: ElfElementTypes.VIDEO,
    props: {
        elementInfo: {
            type: Object as PropType<PPTElfVideoElement>,
            required: true
        }
    },
    setup(props) {
        const videoContainerRef = ref()
        const videoRef = ref()

        const store = useStore()
        const screening = computed(() => store.state.screening)
        const currentSlide = computed<Slide>(() => store.getters.currentSlide)

        const poster = ref<string>(defaultPoster)
        const currentVideo = ref<Player & { poster: string }>()

        const getPoster = () => {
            return new Promise<string>((resolve) => {
                let _poster = props.elementInfo.poster
                if (_poster) {
                    resolve(_poster)
                    return
                }

                const url = props.elementInfo.src
                if (!url) {
                    resolve(defaultPoster)
                }
                const isLocal =
                    url.includes('127.0.0.1') || url.includes('localhost')

                if (!isLocal) {
                    _poster = videoSnapshotOssProcessSuffix(
                        url,
                        props.elementInfo.clipStart || 0
                    )
                }
                fetch(_poster, {
                    method: 'HEAD'
                }).then((res) => {
                    res.status === 200
                        ? resolve(_poster)
                        : resolve(defaultPoster)
                })
            })
        }

        /**
         * 获取视频的duration
         */
        const getVideoDuration = () => {
            const url = props.elementInfo.src
            const audio = new Audio(url)
            audio.preload = 'metadata'
            audio.crossOrigin = 'anonymous'
            audio.addEventListener('durationchange', () => {
                const duration = audio.duration * 1000
                store.commit(MutationTypes.UPDATE_ELEMENT, {
                    id: props.elementInfo.id,
                    props: {
                        duration
                    }
                })
            })
        }

        const nextStep = (nextStep: any) => {
            // 确认是否是发送到 这页ppt 以及 对应的元素
            if (
                nextStep.slideId === currentSlide.value.id &&
                nextStep.elementId === props.elementInfo.id
            ) {
                for (let i = 0; i < nextStep.command.length; i++) {
                    switch (nextStep.command[i].command) {
                        case 'play':
                            onPlay(
                                props.elementInfo?.clipStart
                                    ? props.elementInfo.clipStart / 1000
                                    : 0
                            )
                            break
                        default:
                            break
                    }
                }
            }
        }

        const onPlay = (startTime?: number) => {
            if (!currentVideo.value) {
                return
            }
            currentVideo.value.start(props.elementInfo.src)
            if (startTime) {
                currentVideo.value.currentTime = startTime
            }
            currentVideo.value.play()
            logger.info(
                `【调试日志】视频播放: {name:${props.elementInfo.name},url:${props.elementInfo.src}}`
            )
        }
        const onPause = () => {
            if (currentVideo.value) {
                currentVideo.value.pause()
                logger.info(
                    `【调试日志】视频暂停: {name:${props.elementInfo.name},url:${props.elementInfo.src}}`
                )
            }
        }
        const onStop = () => {
            if (currentVideo.value) {
                currentVideo.value.currentTime = props.elementInfo.clipStart
                    ? props.elementInfo.clipStart / 1000
                    : 0
                currentVideo.value.pause()
                logger.info(
                    `【调试日志】视频停止: {name:${props.elementInfo.name},url:${props.elementInfo.src}}`
                )
            }
        }

        const doAction = () => {
            if (!currentVideo.value) {
                return
            }
            logger.info('video is playing: ', currentVideo.value.paused)
            if (currentVideo.value.paused) {
                onPlay()
            } else {
                onPause()
            }
        }

        watch(
            () => props.elementInfo.poster,
            () => {
                getPoster().then((_poster) => (poster.value = _poster))
            }
        )
        watch(
            () => props.elementInfo.loop,
            () => {
                if (currentVideo.value) {
                    currentVideo.value.destroy()
                }
                initVideo()
            }
        )
        watch(
            () => props.elementInfo.src,
            () => {
                getVideoDuration()
            }
        )

        watch(
            () => poster.value,
            () => {
                if (poster.value) {
                    if (currentVideo.value) {
                        currentVideo.value.poster = poster.value
                    }
                }
            }
        )

        watch(
            () => props.elementInfo.clipStart,
            (newVal) => {
                if (!currentVideo.value || !newVal) {
                    return
                }
                currentVideo.value!.currentTime = newVal / 1000
                currentVideo.value!.poster = videoSnapshotOssProcessSuffix(
                    props.elementInfo.src,
                    newVal
                )
            }
        )
        watch(
            () => props.elementInfo.clipEnd,
            (newVal) => {
                if (!currentVideo.value || !newVal) {
                    return
                }
                currentVideo.value!.currentTime =
                    props.elementInfo.clipStart! / 1000
            }
        )

        const initVideo = () => {
            currentVideo.value = xgplayer(videoRef.value, {
                url: props.elementInfo.src,
                poster: poster.value,
                lang: 'zh-cn',
                ignores: ['volume', 'progress', 'play'],
                controlsList: ['nodownload'],
                controls: true,
                keyShortcut: 'off',
                closeVideoDblclick: true,
                closeVideoClick: !screening.value,
                closeVideoTouch: !screening.value,
                closeInactive: false,
                pip: false,
                loop: props.elementInfo?.loop,
                clip: true,
                getClipStart: () => {
                    return props.elementInfo.clipStart
                },
                getClipEnd: () => {
                    return props.elementInfo.clipEnd
                },
                allowSeekAfterEnded: true,
                allowPlayAfterEnded: true
            }) as Player & { poster: string }
            getVideoDuration()
            getPoster().then((_poster) => (poster.value = _poster))
        }

        onMounted(() => {
            initVideo()
            emitter.on(EmitterEvents.SWITCH_SLIDE_PAGE, onStop)
            emitter.on(EmitterEvents.PPT_STOP_PLAY, onStop)
            emitter.on(EmitterEvents.ON_WEBVIEW_HIDE, onPause)
            emitter.on(EmitterEvents.ON_PPT_HIDE, onPause)
            emitter.on(EmitterEvents.NEXT_STEP, nextStep)
        })

        onBeforeUnmount(() => {
            emitter.off(EmitterEvents.SWITCH_SLIDE_PAGE, onStop)
            emitter.off(EmitterEvents.PPT_STOP_PLAY, onStop)
            emitter.off(EmitterEvents.ON_WEBVIEW_HIDE, onPause)
            emitter.off(EmitterEvents.ON_PPT_HIDE, onPause)
            emitter.off(EmitterEvents.NEXT_STEP, nextStep)
        })

        return {
            videoContainerRef,
            videoRef,
            screening,
            poster,
            doAction,
            onPlay,
            onStop,
            onPause
        }
    }
})
