
import { defineComponent, PropType } from 'vue'
import { PPTLinkButtonElement } from '@/types/slides'
import { ContextmenuItem } from '@/components/Contextmenu/types'
import useLink from '@/hooks/useLink'
export default defineComponent({
	name: 'editable-element-link-button',
	props: {
		elementInfo: {
			type: Object as PropType<PPTLinkButtonElement>,
			required: true
		},
		selectElement: {
			type: Function as PropType<
				(
					e: MouseEvent,
					element: PPTLinkButtonElement,
					canMove?: boolean
				) => void
			>,
			required: true
		},
		contextmenus: {
			type: Function as PropType<() => ContextmenuItem[]>
		}
	},
	setup(props) {
		const handleSelectElement = (e: MouseEvent, canMove = true) => {
			if (props.elementInfo.lock) return
			e.stopPropagation()

			props.selectElement(e, props.elementInfo, canMove)
		}

		const { openWebLink } = useLink()

		const openLink = () => {
			openWebLink(props.elementInfo.link?.target as string)
		}

		return {
			handleSelectElement,
			openLink
		}
	}
})
