
import { defineComponent, PropType, computed } from 'vue'
import { PPTTextElement } from '@/types/slides'
import ElementOutline from '@/views/components/element/ElementOutline.vue'

import useElementShadow from '@/views/components/element/hooks/useElementShadow'

export default defineComponent({
	name: 'base-element-text',
	components: {
		ElementOutline
	},
	props: {
		elementInfo: {
			type: Object as PropType<PPTTextElement>,
			required: true
		}
	},
	setup(props) {
		const shadow = computed(() => props.elementInfo.shadow)
		const content = computed(() => {
			let elementContent = props.elementInfo.content
			if (elementContent.includes('<p style=><span></span></p>')) {
				elementContent = elementContent.replaceAll(
					'<p style=><span></span></p>',
					'<p style=><br class="ProseMirror-trailingBreak"/></p>'
				)
			}
			return elementContent
		})
		const { shadowStyle } = useElementShadow(shadow)

		return {
			shadowStyle,
			content
		}
	}
})
