import { ElfElementTypes } from '@/types/slides'
import mainComponent from './main.vue'

export default {
	initData: {
		subtype: ElfElementTypes.ENJOY,
		width: -1,
		height: -1
	},
	config: {
		editPreview: true,
		scaleLock: true, // 大小锁定标识
		focusOrder: 1
	},
	mainComponent
}
