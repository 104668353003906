// 载入背景
const imgBg1Path = require('@/assets/images/singplay-toolbar/singplay-background/singplay-bg1.jpg')
const imgBg2Path = require('@/assets/images/singplay-toolbar/singplay-background/singplay-bg2.jpg')
const imgBg3Path = require('@/assets/images/singplay-toolbar/singplay-background/singplay-bg3.jpg')
const imgBg4Path = require('@/assets/images/singplay-toolbar/singplay-background/singplay-bg4.jpg')
const imgBg5Path = require('@/assets/images/singplay-toolbar/singplay-background/singplay-bg5.jpg')
const imgBg6Path = require('@/assets/images/singplay-toolbar/singplay-background/singplay-bg6.jpg')
const imgBg7Path = require('@/assets/images/singplay-toolbar/singplay-background/singplay-bg7.jpg')
const imgBg8Path = require('@/assets/images/singplay-toolbar/singplay-background/singplay-bg8.jpg')
const imgBg9Path = require('@/assets/images/singplay-toolbar/singplay-background/singplay-bg9.jpg')
const imgBg10Path = require('@/assets/images/singplay-toolbar/singplay-background/singplay-bg10.jpg')

// 载入光标
const cursorPath1 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor1.png')
const cursorPath2 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor2.png')
const cursorPath3 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor3.png')
const cursorPath4 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor4.png')
const cursorPath5 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor5.png')
const cursorPath6 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor6.png')
const cursorPath7 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor7.png')
const cursorPath8 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor8.jpg')
const cursorPath9 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor9.png')
const cursorPath10 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor10.png')
const cursorPath11 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor11.png')
const cursorPath12 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor12.png')
const cursorPath13 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor13.png')
const cursorPath14 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor14.png')
const cursorPath15 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor15.png')
const cursorPath16 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor16.png')
const cursorPath17 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor17.png')
const cursorPath18 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor18.png')
const cursorPath19 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor19.png')
const cursorPath20 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor20.png')
const cursorPath21 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor21.png')
const cursorPath22 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor22.png')
const cursorPath23 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor23.png')
const cursorPath24 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor24.png')
const cursorPath25 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor25.png')
const cursorPath26 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor26.png')
const cursorPath27 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor27.png')
const cursorPath28 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor28.png')
const cursorPath29 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor29.png')
const cursorPath30 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor30.png')
const cursorPath31 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor31.png')
const cursorPath32 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor32.png')
const cursorPath33 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor33.jpg')
const cursorPath34 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor34.png')
const cursorPath35 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor35.png')
const cursorPath36 = require('@/assets/images/singplay-toolbar/singplay-cursor/cursor36.png')

export type ImageType = {
	url: string
	value: string
}

export const imgBgMap = [
	{
		url: '',
		value: 'default'
	},
	{
		url: imgBg1Path,
		value: 'bg1'
	},
	{
		url: imgBg2Path,
		value: 'bg2'
	},
	{
		url: imgBg3Path,
		value: 'bg3'
	},
	{
		url: imgBg4Path,
		value: 'bg4'
	},
	{
		url: imgBg5Path,
		value: 'bg5'
	},
	{
		url: imgBg6Path,
		value: 'bg6'
	},
	{
		url: imgBg7Path,
		value: 'bg7'
	},
	{
		url: imgBg8Path,
		value: 'bg8'
	},
	{
		url: imgBg9Path,
		value: 'bg9'
	},
	{
		url: imgBg10Path,
		value: 'bg10'
	}
]

export const BackgroundImageMap: Record<string, string> = {
	default: '',
	bg1: imgBg1Path,
	bg2: imgBg2Path,
	bg3: imgBg3Path,
	bg4: imgBg4Path,
	bg5: imgBg5Path,
	bg6: imgBg6Path,
	bg7: imgBg7Path,
	bg8: imgBg8Path,
	bg9: imgBg9Path,
	bg10: imgBg10Path
}
export const MelodyCursorImageMap: Record<string, string> = {
	cursor1: cursorPath1,
	cursor2: cursorPath2,
	cursor3: cursorPath3,
	cursor4: cursorPath4,
	cursor5: cursorPath5,
	cursor6: cursorPath6,
	cursor7: cursorPath7,
	cursor8: cursorPath8,
	cursor9: cursorPath9,
	cursor10: cursorPath10,
	cursor11: cursorPath11,
	cursor12: cursorPath12,
	cursor13: cursorPath13,
	cursor14: cursorPath14,
	cursor15: cursorPath15,
	cursor16: cursorPath16,
	cursor17: cursorPath17,
	cursor18: cursorPath18,
	cursor19: cursorPath19,
	cursor20: cursorPath20,
	cursor21: cursorPath21,
	cursor22: cursorPath22,
	cursor23: cursorPath23,
	cursor24: cursorPath24,
	cursor25: cursorPath25,
	cursor26: cursorPath26,
	cursor27: cursorPath27,
	cursor28: cursorPath28,
	cursor29: cursorPath29,
	cursor30: cursorPath30,
	cursor31: cursorPath31,
	cursor32: cursorPath32,
	cursor33: cursorPath33,
	cursor34: cursorPath34,
	cursor35: cursorPath35,
	cursor36: cursorPath36
}

export const cursorMap = [
	{
		url: cursorPath1,
		value: 'cursor1'
	},
	{
		url: cursorPath2,
		value: 'cursor2'
	},
	{
		url: cursorPath3,
		value: 'cursor3'
	},
	{
		url: cursorPath4,
		value: 'cursor4'
	},
	{
		url: cursorPath5,
		value: 'cursor5'
	},
	{
		url: cursorPath6,
		value: 'cursor6'
	},
	{
		url: cursorPath7,
		value: 'cursor7'
	},
	{
		url: cursorPath8,
		value: 'cursor8'
	},
	{
		url: cursorPath9,
		value: 'cursor9'
	}
]

// 背景和光标的图片默认大小
export const defaultImgSize = {
	backgroundHeight: 1920,
	backgroundWidth: 1080,
	cursorHeight: 50,
	cursorWidth: 50
}

// 预设参数事件
export const PreviewParamsEvent = {
	CHANGE_PREVIEW_PARAMS: 'change-preview-params',
	SING_PLAY_PARAMS_CHANGE: 'sing-play-params-change'
}

// 嵌入组件参数配置事件
export const InsetPlaySettingEvent = {
	SING_PLAY_INSET_SETTING_CHANGE: 'sing-play-inset-setting-change'
}

export const DisplayMode = {
	INSET: 'inset', // 嵌入
	FULL: 'full', // 铺满
	CREATE: 'create' // 创编模式
}

export const SheetElementTypes = {
	FUll_SING_PLAY: 1,
	INSET_SING_PLAY: 2,
	CREATION_SING_PLAY: 3
}