
import { defineComponent, ref, computed, onMounted } from 'vue'
import { MutationTypes, useStore } from '@/store'
import useUpLoadResource from '@/hooks/useUpLoadResource'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'
import logger from '@evideo/logger'
import { message } from 'ant-design-vue'
import { PPTElfQuestionsElement } from '@/types/slides'
import { imageBackgroundPathMap } from './questionMap'

export default defineComponent({
    name: 'question-style-panel',
    setup() {
        const store = useStore()
        const handleElement = computed<PPTElfQuestionsElement>(
            () => store.getters.handleElement
        )

        const imageBackgroundPath = [
            [
                Object.keys(imageBackgroundPathMap)[0],
                Object.keys(imageBackgroundPathMap)[1]
            ],
            [
                Object.keys(imageBackgroundPathMap)[2],
                Object.keys(imageBackgroundPathMap)[3]
            ]
        ]
        // 是否作答
        const answerVisible = ref(
            handleElement.value?.defaultAnswer === false ? false : true
        )
        // 是否开启背景
        const backgroundVisible = ref(
            handleElement.value?.useBackground === false ? false : true
        )
        // 是否使用默认背景图片
        const isDefaultImg = ref(
            handleElement.value?.defaultBackground === false ? false : true
        )
        const backgroundCustomImageUrl = ref('')
        const selectedBackgroundImage = ref('')

        const { getImageResourceUrl } = useUpLoadResource()
        const { addHistorySnapshot } = useHistorySnapshot()

        const resetImageUrl = () => {
            isDefaultImg.value = false
            selectedBackgroundImage.value = ''
            backgroundCustomImageUrl.value = ''
        }
        // 上传自定义背景图片
        const uploadBackgroundImage = async (files: File[]) => {
            const imageFile = files[0]
            if (!imageFile) return
            try {
                const res = await getImageResourceUrl(imageFile)
                resetImageUrl()
                backgroundCustomImageUrl.value = res.url
                updateElementInfo()
            } catch (e) {
                logger.error(e)
                message.error('插入图片失败')
            }
        }
        // 清空自定义图片
        const handleClearCustomImage = () => {
            backgroundCustomImageUrl.value = ''
            updateElementInfo()
        }
        // 更新组件参数
        const updateElementInfo = () => {
            const props = {
                useBackground: backgroundVisible.value,
                defaultAnswer: answerVisible.value,
                defaultBackground: isDefaultImg.value,
                backgroundImage:
                    backgroundCustomImageUrl.value ||
                    selectedBackgroundImage.value
            }
            store.commit(MutationTypes.UPDATE_ELEMENT, {
                id: handleElement.value.id,
                props
            })
            setTimeout(() => {
                addHistorySnapshot()
            }, 200)
        }
        // 改变答案显示与否
        const handleChangeAnswerVisible = (state: boolean) => {
            answerVisible.value = state
            updateElementInfo()
        }
        // 改变背景启用与否
        const handleChangeBackgroundVisible = (state: boolean) => {
            resetImageUrl()
            backgroundVisible.value = state
            // 开启背景默认开启默认背景
            if (state) {
                isDefaultImg.value = true
            }
            updateElementInfo()
        }
        // 改变默认背景启用与否
        const handleChangeDefaultImg = (state: boolean) => {
            resetImageUrl()
            isDefaultImg.value = state
            updateElementInfo()
        }
        // 选择背景项目
        const handleChangeBackgroundImage = (imageKey: string) => {
            if (imageKey === handleElement.value?.backgroundImage) {
                return
            }
            resetImageUrl()
            selectedBackgroundImage.value = imageKey
            updateElementInfo()
        }
        onMounted(() => {
            if (handleElement.value?.backgroundImage) {
                if (
                    Object.keys(imageBackgroundPathMap).includes(
                        handleElement.value.backgroundImage
                    )
                ) {
                    selectedBackgroundImage.value =
                        handleElement.value.backgroundImage
                } else {
                    backgroundCustomImageUrl.value =
                        handleElement.value.backgroundImage
                }
            }
        })
        return {
            imageBackgroundPath,
            answerVisible,
            backgroundVisible,
            isDefaultImg,
            imageBackgroundPathMap,
            backgroundCustomImageUrl,
            selectedBackgroundImage,
            uploadBackgroundImage,
            handleClearCustomImage,
            handleChangeAnswerVisible,
            handleChangeBackgroundVisible,
            handleChangeDefaultImg,
            handleChangeBackgroundImage
        }
    }
})
