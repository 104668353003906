
import {
  defineComponent,
  inject,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
} from "vue";
import { ElfElementTypes, PPTElfAudioElement } from "@/types/slides";
import emitter, { EmitterEvents } from "@/utils/emitter";
import { MutationTypes, useStore } from "@/store";
import logger from "@evideo/logger";
import Audio from "@/components/Audio.vue";

export default defineComponent({
  name: ElfElementTypes.AUDIO,
  components: {
    Audio,
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTElfAudioElement>,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();

    const previewSign = inject<boolean>("previewSign");

    const audioRef = ref<typeof Audio>();
    const doAction = () => {
      const player = audioRef.value;
      logger.info("audio is playing: ", player?.paused);
      if (player) {
        if (player.paused) {
          player.play();
          logger.info(
            `【调试日志】 音频播放: {name:${props.elementInfo.name},url:${props.elementInfo.src}}`
          );
        } else {
          player.pause();
          logger.info(
            `【调试日志】音频暂停: {name:${props.elementInfo.name},url:${props.elementInfo.src}}`
          );
        }
      }
    };
    const stopAudio = () => {
      const player = audioRef.value;
      player && player.stop();
    };
    const pauseAudio = () => {
      const player = audioRef.value;
      player && !player.paused && player.pause();
      logger.info(
        `【调试日志】音频暂停: {name:${props.elementInfo.name},url:${props.elementInfo.src}}`
      );
    };
    const nextStep = (nextStep: any) => {
      // 确认是否是发送到  对应的元素
      if (nextStep.elementId === props.elementInfo.id) {
        for (let i = 0; i < nextStep.command.length; i++) {
          switch (nextStep.command[i].command) {
            case "play":
              doAction();
              break;
            default:
              break;
          }
        }
      }
    };

    /**
     * !这边根据大小计算是为了做新版的兼容，新版无法拉伸大小，为了兼容旧版直接根据大小来硬改大小
     */
    const initAudio = () => {
      console.log("initAudio");
      // if (
      //     props.elementInfo.width === 180 ||
      //     props.elementInfo.width === 500
      // ) {
      //     return
      // }
      if (props.elementInfo.width <= 400) {
        onShowAudioInfo(false);
      } else {
        onShowAudioInfo(true);
      }
    };
    const getAudioDuration = (audio: HTMLAudioElement | undefined) => {
      if (audio) {
        audio.addEventListener("durationchange", () => {
          const duration = (audio?.duration || 0) * 1000;
          store.commit(MutationTypes.UPDATE_ELEMENT, {
            id: props.elementInfo.id,
            props: {
              duration,
            },
          });
        });
      }
    };
    const onShowAudioInfo = (val: boolean) => {
      let width = 180;
      if (val) {
        width = 500;
      }
      store.commit(MutationTypes.UPDATE_ELEMENT, {
        id: props.elementInfo.id,
        props: {
          width,
        },
      });
    };
    onMounted(() => {
      initAudio();
      if (!previewSign) {
        emitter.on(EmitterEvents.SWITCH_SLIDE_PAGE, stopAudio);
        emitter.on(EmitterEvents.PPT_STOP_PLAY, stopAudio);
        emitter.on(EmitterEvents.ON_WEBVIEW_HIDE, pauseAudio);
        emitter.on(EmitterEvents.ON_PPT_HIDE, pauseAudio);
        emitter.on(EmitterEvents.NEXT_STEP, nextStep);
      }
    });
    onBeforeUnmount(() => {
      if (!previewSign) {
        emitter.off(EmitterEvents.SWITCH_SLIDE_PAGE, stopAudio);
        emitter.off(EmitterEvents.PPT_STOP_PLAY, stopAudio);
        emitter.off(EmitterEvents.ON_WEBVIEW_HIDE, pauseAudio);
        emitter.off(EmitterEvents.ON_PPT_HIDE, pauseAudio);
        emitter.off(EmitterEvents.NEXT_STEP, nextStep);
      }
    });
    return {
      audioRef,
      doAction,
      onShowAudioInfo,
      previewSign,
      getAudioDuration,
    };
  },
});
