import { computed, Ref } from "vue";

const getDistance = (p1: Touch, p2: Touch) => {
  const x = p2.pageX - p1.pageX,
    y = p2.pageY - p1.pageY;
  return Math.sqrt(x * x + y * y);
};

const getAngle = (p1: any, p2: any) => {
  const x = p1.pageX - p2.pageX,
    y = p1.pageY - p2.pageY;
  return (Math.atan2(y, x) * 180) / Math.PI;
};

export default (
  dom: HTMLElement | Ref<HTMLElement | null>,
  gestureEvent: {
    gesturestart?: (e: any) => void;
    gesturechange?: (e: any) => void;
    gestureend?: (e: any) => void;
  } = {}
) => {
  const gesturestart = new CustomEvent("gesturestart");
  const gesturechange = new CustomEvent("gesturechange");
  const gestureend = new CustomEvent("gestureend");

  const gestureDom = computed(() => {
    let _dom = dom as HTMLElement;
    // eslint-disable-next-line no-prototype-builtins
    if (dom.hasOwnProperty("value") || dom.hasOwnProperty("_value")) {
      _dom = (dom as Ref<HTMLElement>).value;
    }
    return _dom;
  });
  let istouch = false;
  let start: any[] = [];

  const touchstart = (e: TouchEvent) => {
    if (e.touches.length >= 2) {
      // 判断是否有两个点在屏幕上
      istouch = true;
      start = e.touches as any; // 得到第一组两个点
      gestureDom.value.dispatchEvent(gesturestart);
    }
  };
  const touchmove = (e: TouchEvent) => {
    if (e.touches.length >= 2 && istouch) {
      e.preventDefault();
      const now = e.touches; // 得到第二组两个点
      const scale =
        getDistance(now[0], now[1]) / getDistance(start[0], start[1]); // 得到缩放比例
      const rotation = getAngle(now[0], now[1]) - getAngle(start[0], start[1]); // 得到旋转角度差
      (gesturechange as any).scale = scale.toFixed(2);
      (gesturechange as any).rotation = rotation.toFixed(2);
      gestureDom.value.dispatchEvent(gesturechange);
    }
  };

  const touchend = () => {
    if (istouch) {
      istouch = false;
      gestureDom.value.dispatchEvent(gestureend);
    }
  };

  const initGestureEvent = () => {
    if (gestureDom.value) {
      gestureDom.value.addEventListener("touchstart", touchstart);
      gestureDom.value.addEventListener("touchmove", touchmove);
      gestureDom.value.addEventListener("touchend", touchend);
      gestureEvent.gesturestart &&
        gestureDom.value.addEventListener(
          "gesturestart",
          gestureEvent.gesturestart
        );
      gestureEvent.gesturechange &&
        gestureDom.value.addEventListener(
          "gesturechange",
          gestureEvent.gesturechange
        );
      gestureEvent.gestureend &&
        gestureDom.value.addEventListener(
          "gestureend",
          gestureEvent.gestureend
        );
    } else {
      console.log("geture event init failed");
    }
  };

  const destroyGestureEvent = () => {
    if (gestureDom.value) {
      gestureDom.value.removeEventListener("touchstart", touchstart);
      gestureDom.value.removeEventListener("touchmove", touchmove);
      gestureDom.value.removeEventListener("touchend", touchend);
      gestureEvent.gesturestart &&
        gestureDom.value.removeEventListener(
          "gesturestart",
          gestureEvent.gesturestart
        );
      gestureEvent.gesturechange &&
        gestureDom.value.removeEventListener(
          "gesturechange",
          gestureEvent.gesturechange
        );
      gestureEvent.gestureend &&
        gestureDom.value.removeEventListener(
          "gestureend",
          gestureEvent.gestureend
        );
    } else {
      console.warn('geture event no find gestureDom,can"t destory gesture');
    }
  };

  return {
    initGestureEvent,
    destroyGestureEvent,
  };
};
