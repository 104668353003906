import { ElfElementTypes } from '@/types/slides'
import mainComponent from './main.vue'

export default {
  initData: {
    subtype: ElfElementTypes.MUSIC_RHYTHM,
    width: -1,
    height: -1
  },
  config: {
    scaleLock: true, // 大小锁定标识
    focusOrder: 1
  },
  mainComponent
}
