import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-5543fbaf")
const _hoisted_1 = { class: "element-content" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditableTable = _resolveComponent("EditableTable")!
  const _directive_contextmenu = _resolveDirective("contextmenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["editable-element-table", { lock: _ctx.elementInfo.lock }]),
    ref: "elementRef",
    style: _normalizeStyle({
			top: _ctx.elementInfo.top + 'px',
			left: _ctx.elementInfo.left + 'px',
			width: _ctx.elementInfo.width + 'px'
		})
  }, [
    _withDirectives(_createElementVNode("div", _hoisted_1, [
      _createVNode(_component_EditableTable, {
        onMousedown: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"])),
        data: _ctx.elementInfo.data,
        width: _ctx.elementInfo.width,
        colWidths: _ctx.elementInfo.colWidths,
        outline: _ctx.elementInfo.outline,
        theme: _ctx.elementInfo.theme,
        editable: _ctx.editable,
        onChange: _cache[1] || (_cache[1] = (data) => _ctx.updateTableCells(data)),
        onChangeColWidths: _cache[2] || (_cache[2] = (widths) => _ctx.updateColWidths(widths)),
        onChangeSelectedCells: _cache[3] || (_cache[3] = (cells) => _ctx.updateSelectedCells(cells))
      }, null, 8, ["data", "width", "colWidths", "outline", "theme", "editable"]),
      (!_ctx.editable || _ctx.elementInfo.lock)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["table-mask", { lock: _ctx.elementInfo.lock }]),
            onDblclick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.startEdit())),
            onMousedown: _cache[5] || (_cache[5] = ($event) => _ctx.handleSelectElement($event))
          }, [
            _createElementVNode("div", {
              class: "mask-tip",
              style: _normalizeStyle({ transform: `scale(${1 / _ctx.canvasScale})` })
            }, " 双击编辑 ", 4)
          ], 34))
        : _createCommentVNode("", true)
    ], 512), [
      [_directive_contextmenu, _ctx.contextmenus]
    ])
  ], 6))
}