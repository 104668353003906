import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-46b2cdc6")
const _hoisted_1 = { class: "background-images-wrapper" }
const _hoisted_2 = { class: "image" }
const _hoisted_3 = ["src"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.currentImage,
        class: "currentImage",
        draggable: "false"
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", {
      class: "switch-button",
      style: _normalizeStyle({ display: _ctx.isDefaultBg ? 'none' : '' })
    }, [
      _createElementVNode("div", {
        class: _normalizeClass(["prevpage", _ctx.isFirst ? 'hidden' : '']),
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onPrevPage && _ctx.onPrevPage(...args)))
      }, null, 2),
      _createElementVNode("div", {
        class: _normalizeClass(["nextpage", _ctx.isLast ? 'hidden' : '']),
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onNextPage && _ctx.onNextPage(...args)))
      }, null, 2)
    ], 4)
  ]))
}