import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-627e39f4")
const _hoisted_1 = { class: "mic-app-ele" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.microApp), _mergeProps({ key: _ctx.elId }, _ctx.micAppData, {
      action: _ctx.compAction,
      platform: _ctx.platform
    }), null, 16, ["action", "platform"]))
  ]))
}