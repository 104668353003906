
import { defineComponent, toRaw, PropType, ref, watch, computed } from 'vue'
import { ContextmenuItem } from '@/components/Contextmenu/types'
import usePopulateElem from '@/hooks/usePopulateElem'
import { ElfElementTypes, PPTElfElement, PPTElfSongElement } from '@/types/slides'
import { findElfByType } from './register'

export default defineComponent({
	name: 'editable-element-elf',
	props: {
		elementInfo: {
			type: Object as PropType<PPTElfElement>,
			required: true
		},
		selectElement: {
			type: Function as PropType<
				(e: MouseEvent, element: PPTElfElement, canMove?: boolean) => void
			>,
			required: true
		},
		contextmenus: {
			type: Function as PropType<() => ContextmenuItem[]>
		}
	},
	setup(props) {
		const currentElfComponent = computed(() => {
			const config = findElfByType(props.elementInfo.subtype)
			return config?.mainComponent
		})
		const editPreviewTrueFalse = computed(() => {
			const config = findElfByType(props.elementInfo.subtype)
      if (props.elementInfo.subtype === ElfElementTypes.SING_PLAY && (props.elementInfo as PPTElfSongElement)?.displayMode === 'inset') {
        return true
      }
			return config?.config?.editPreview
		})
		const isPopulate = ref(false)
		const { populateElem } = usePopulateElem()
		const elfElementInfo = ref(toRaw(props.elementInfo))
		watch(
			() => props.elementInfo,
			(newVal) => {
				elfElementInfo.value = toRaw(newVal)
				// 主要针对题目组件背景编辑时，配置信息及时更新
				populateElem(elfElementInfo.value).then((_elfElementInfo: any) => {
					elfElementInfo.value = _elfElementInfo
				})
			}
		)
		populateElem(elfElementInfo.value).then((_elfElementInfo: any) => {
			// props.elementInfo = elementInfo
			elfElementInfo.value = _elfElementInfo
			isPopulate.value = true
		})

		const handleSelectElement = (e: MouseEvent) => {
			if (elfElementInfo.value.lock) return
			e.stopPropagation()

			props.selectElement(e, elfElementInfo.value)
		}

		return {
			handleSelectElement,
			currentElfComponent,
			elfElementInfo,
			isPopulate,
			editPreviewTrueFalse
		}
	}
})
