// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/enjoy-song/dialog_enjoy_title.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/enjoy-song/dialog_enjoy_controls.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".enjoy-song[data-v-dee57118]{position:sticky;width:100%;height:100%}.enjoy-song .title[data-v-dee57118]{font-size:30px;font-weight:700;line-height:24px;position:absolute;z-index:2;top:60px;display:flex;align-items:center;justify-content:center;width:100%;height:80px;text-align:center;color:#fff;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:50%}.enjoy-song .title .title-content[data-v-dee57118]{margin-left:50px;text-align:center;color:#764b19}.enjoy-song .slide-show-area[data-v-dee57118]{position:absolute;z-index:1;width:100%;height:80.7%}.enjoy-song .playback-controls-area[data-v-dee57118]{position:absolute;z-index:10;bottom:0;width:100%;height:19.3%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-repeat:no-repeat;background-size:cover}", ""]);
// Exports
module.exports = exports;
