import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_contextmenu = _resolveDirective("contextmenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: "editable-element-elf",
    style: _normalizeStyle({
			top: _ctx.elfElementInfo.top + 'px',
			left: _ctx.elfElementInfo.left + 'px',
			width: _ctx.elfElementInfo.width + 'px',
			height: _ctx.elfElementInfo.height + 'px'
		})
  }, [
    (!_ctx.editPreviewTrueFalse)
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "element-content",
          onMousedown: _cache[0] || (_cache[0] = ($event) => _ctx.handleSelectElement($event)),
          onClickCapture: _cache[1] || (_cache[1] = _withModifiers(() => {}, ["prevent","stop"]))
        }, [
          (_ctx.isPopulate && _ctx.currentElfComponent)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentElfComponent), {
                elementInfo: _ctx.elfElementInfo,
                key: _ctx.elfElementInfo.id,
                isEdit: true
              }, null, 8, ["elementInfo"]))
            : _createCommentVNode("", true)
        ], 544)), [
          [_directive_contextmenu, _ctx.contextmenus]
        ])
      : _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "element-content",
          onMousedown: _cache[2] || (_cache[2] = ($event) => _ctx.handleSelectElement($event))
        }, [
          (_ctx.isPopulate && _ctx.currentElfComponent)
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentElfComponent), {
                elementInfo: _ctx.elfElementInfo,
                key: _ctx.elfElementInfo.id,
                isEdit: true
              }, null, 8, ["elementInfo"]))
            : _createCommentVNode("", true)
        ], 544)), [
          [_directive_contextmenu, _ctx.contextmenus]
        ])
  ], 4))
}