
import { computed, defineComponent, ref, watch, watchEffect } from 'vue'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'
import useLocalCDN from '@/hooks/useLocalCDN'
import { MutationTypes, useStore } from '@/store'
import { PPTElfVideoElement } from '@/types/slides'
import UploadProgress from '@/components/UploadProgress.vue'
import { UpLoadReply } from '@/hooks/useUpLoadResource'
import { millisecondsFormat } from '@/utils/formatTime'
import _ from 'lodash'

export default defineComponent({
  name: 'tool-bar',
  components: { UploadProgress },
  setup() {
    const store = useStore()
    const { addHistorySnapshot } = useHistorySnapshot()
    const { getFileNameFromCDNUrl } = useLocalCDN()

    const handleElement = computed<PPTElfVideoElement>(
        () => store.getters.handleElement
    )

    const fileName = ref('')
    const uploadVideoProgressShow = ref(false)
    const nextPlay = ref(
        handleElement.value?.nextStepEvents?.includes('play') || false
    )
    const videoLoop = ref(handleElement.value.loop || false)
    const uploadVideoFile = ref<File>()

    const posterName = ref('')
    const uploadPosterProgressShow = ref(false)
    const uploadPosterFile = ref<File>()

    const clipPoints = ref([handleElement.value.clipStart || 0, handleElement.value.clipEnd || handleElement.value.duration || 0])
    const clipLimitPoints = [0, handleElement.value.duration || 0]
    const clipPointsFormat = computed(() => (clipPoints.value.map(time => {
      return millisecondsFormat(time as number)
    })))
    const onVideoFileChange = (files: File[]) => {
      const videoFile = files[0]
      if (!videoFile) return
      uploadVideoFile.value = videoFile
      uploadVideoProgressShow.value = true
    }

    const handleVideoOk = (res: UpLoadReply) => {
      const props = Object.assign({}, handleElement.value, {
        src: res.url,
        md5: res.md5,
        videoSize: res.size,
        size: (res.size + handleElement.value.posterSize) | 0,
        name: fileName.value
      })
      uploadVideoProgressShow.value = false
      store.commit(MutationTypes.UPDATE_ELEMENT, {
        id: handleElement.value.id,
        props
      })
      addHistorySnapshot()
    }

    const handleVideoCancel = () => {
      uploadVideoProgressShow.value = false
    }

    const onPosterFileChange = (files: File[]) => {
      const posterFile = files[0]
      if (!posterFile) return
      uploadPosterFile.value = posterFile
      uploadPosterProgressShow.value = true
    }

    const handlePosterOk = (res: UpLoadReply) => {
      const props = Object.assign({}, handleElement.value, {
        poster: res.url,
        posterSize: res.size | 0,
        size: (res.size + handleElement.value.videoSize) | 0
      })
      uploadPosterProgressShow.value = false
      store.commit(MutationTypes.UPDATE_ELEMENT, {
        id: handleElement.value.id,
        props
      })
      addHistorySnapshot()
    }

    const handlePosterCancel = () => {
      uploadPosterProgressShow.value = false
    }

    const updateNextStepEvents = (value: boolean, status: any) => {
      if (value) {
        if (Array.isArray(handleElement.value.nextStepEvents)) {
          handleElement.value.nextStepEvents.push(status)
        } else {
          handleElement.value.nextStepEvents = [status]
        }
      } else {
        // handleElement.value.nextStepEvents = handleElement.value.nextStepEvents.filter(
        // 	(item: any) => item !== status
        // )
        delete handleElement.value.nextStepEvents
      }
      store.commit(MutationTypes.UPDATE_ELEMENT, {
        id: handleElement.value.id,
        props: {
          ...handleElement.value
        }
      })
      addHistorySnapshot()
    }

    const updateLoop = () => {
      store.commit(MutationTypes.UPDATE_ELEMENT, {
        id: handleElement.value.id,
        props: {
          ...handleElement.value,
          loop: videoLoop.value
        }
      })
      addHistorySnapshot()
    }

    watchEffect(async () => {
      if (handleElement.value) {
        const _fileName = await getFileNameFromCDNUrl(
            handleElement.value.src
        )
        fileName.value = _fileName === '' ? '点击选择文件' : _fileName
        const _posterName = await getFileNameFromCDNUrl(
            handleElement.value.poster
        )
        posterName.value =
            _posterName === '' ? '点击选择文件' : _posterName
      }
    })

    const onClipPointChange = _.debounce((values: Array<number>) => {
      store.commit(MutationTypes.UPDATE_ELEMENT, {
        id: handleElement.value.id,
        props: {
          ...handleElement.value,
          clipStart: values[0],
          clipEnd: values[1]
        }
      })
      addHistorySnapshot()
    }, 1000)

    watch(() => handleElement.value?.duration, (newVal, oldVal) => {
      if (newVal && oldVal === undefined && handleElement.value?.clipEnd === undefined) {
        clipLimitPoints[1] = newVal
        clipPoints.value[1] = newVal
      }
    }, {immediate: true})
    return {
      fileName,
      videoLoop,
      nextPlay,
      updateNextStepEvents,
      updateLoop,
      posterName,
      uploadVideoProgressShow,
      uploadVideoFile,
      onVideoFileChange,
      handleVideoOk,
      handleVideoCancel,
      uploadPosterProgressShow,
      uploadPosterFile,
      onPosterFileChange,
      handlePosterOk,
      handlePosterCancel,
      clipPoints,
      clipLimitPoints,
      clipPointsFormat,
      onClipPointChange
    }
  }
})
