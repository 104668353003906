import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, vShow as _vShow, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-24b9a62e")
const _hoisted_1 = {
  key: 0,
  class: "question"
}
_popScopeId()

import _ from 'lodash'
import { PPTElfQuestionsElement } from '@/types/slides'
import {
  computed,
  inject,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  Ref,
  watch
} from 'vue'
import ExaminationQuestion from '@evideo/design-vue/lib/examination-question'
import '@evideo/design-vue/lib/examination-question/style'
import { MutationTypes, useStore } from '@/store'
import emitter, { EmitterEvents } from '@/utils/emitter'
import usePageLoading from '@/hooks/usePageLoading'
import useDeleteElement from '@/hooks/useDeleteElement'
import useShowMessage from '@/hooks/useShowMessage'
import { VIEWPORT_SIZE } from '@/configs/canvas'
import {
  imageBackgroundPathMap,
  noSupportQuestionType,
  QuestionType
} from './questionMap'

// eslint-disable-next-line no-undef

export default _defineComponent({
  props: {
  elementInfo: {
    type: Object as PropType<PPTElfQuestionsElement>,
    required: true
  },
  scale: {
    type: Number,
    require: true
  }
},
  setup(__props) {

const props = __props


const store = useStore()
const loading = ref(false)
const showCurrentLoading = ref(false) // 仅展示当前题目的加载页面
const firstLoading = ref(true)
const questionRef = ref<any>(null)
const screenType = computed(() => store.state.screenType)
const width = VIEWPORT_SIZE
const height = computed(() => width * store.state.viewportRatio)
const slideIndex = computed(() => store.state.slideIndex)
const scale: Ref<number> = inject('slideScale') || ref(1)

const { showLoading, closeLoading } = usePageLoading()
const { deleteElement } = useDeleteElement()
const { showMeassage } = useShowMessage()

watch(
    () => slideIndex.value,
    () => {
      const currentSlide = store.state.slides[slideIndex.value]
      const questionId = props.elementInfo.id
      let questionReset = false
      // 判断该题目是否在正在显示的幻灯片上
      // 不在，则重置题目，清空作答停止音视频
      // 在，给出相应情况的提示
      currentSlide?.elements.every((element) => {
        if (element.id === questionId) {
          showCurrentLoading.value = true
          // 编辑模式下，切换幻灯片不需要重置题目
          if (screenType.value === 'edit-check') {
            questionReset = false
          }
          // 当前题目组件在最前面展示时，才给出相应提示
          // 题目是否删除
          if (!props.elementInfo._meta && !store.state.pageLoading) {
            store.commit(MutationTypes.SET_ACTIVE_ELEMENT_ID_LIST, [
              props.elementInfo.id
            ])
            deleteElement()
            showMeassage('该题目已删除')
          }
          // 套题是否过滤，仅在套题有做过滤处理时给出提示
          if (
              props.elementInfo?._meta?.type === QuestionType.netingQustion &&
              !store.state.pageLoading
          ) {
            // 延迟等待questionData数据
            setTimeout(() => {
              if (
                  questionData?.value &&
                  questionData.value.examinationQuestion.sub_questions.length !==
                  props.elementInfo._meta.sub_questions.length
              ) {
                showMeassage('套题将过滤不支持题型')
              }
            }, 10)
          }
          return false
        }
        questionReset = true
        return true
      })
      // 第一次题目加载不触发重置
      if (questionReset && !firstLoading.value) {
        resetQuestion()
      }
      firstLoading.value = false
    },
    { immediate: true }
)

const questionData = computed(() => {
  const elementInfo = _.cloneDeep(props.elementInfo)
  let backgroundImagePath = elementInfo?.backgroundImage
  // 默认题目参数配置
  if (elementInfo?.useBackground === undefined) {
    elementInfo.useBackground = true
    elementInfo.defaultAnswer = true
    elementInfo.defaultBackground = true
  }
  if (backgroundImagePath) {
    if (Object.keys(imageBackgroundPathMap).includes(backgroundImagePath)) {
      backgroundImagePath = imageBackgroundPathMap[backgroundImagePath]
    }
  }

  let data = {
    examinationQuestion: elementInfo?._meta || {},
    defaultBackground:
        elementInfo.useBackground && elementInfo.defaultBackground,
    backgroundImagePath,
    token: store.state.token,
    disabled: !elementInfo?.defaultAnswer,
    aiJudgeApi: `https://${process.env.VUE_APP_EXAM_CENTER_HOST}/common/public-ai-judge`,
    extData: {}
  }

  if (elementInfo._meta?.sub_questions?.length > 0) {
    // 套题过滤不支持的题型
    elementInfo._meta.sub_questions = elementInfo._meta.sub_questions.filter(
        (item: any) => !noSupportQuestionType.includes(item.type)
    )
    // 套题过滤题型后没有题目，则删除整个题目元素
    if (elementInfo._meta.sub_questions.length === 0) {
      deleteElement()
    }
    elementInfo._meta.sub_questions.forEach((item: any) => {
      data = getExtData(data, JSON.parse(JSON.stringify(item)))
    })
  } else {
    data = getExtData(data, elementInfo)
  }
  // 解决幻灯片前后是相同题目，拖拽项目会生成两个问题
  data.examinationQuestion.id = elementInfo._meta.id + elementInfo.id

  if (questionRef.value) {
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    questionRef.value.showJudgeNotifi = false
  }

  return data
})

const getExtData = (data: any, elementInfo: any) => {
  if (!elementInfo) {
    return data
  }
  if (
      elementInfo?._meta?.type === QuestionType.sheetMusicCreation ||
      elementInfo?.type === QuestionType.sheetMusicCreation
  ) {
    let keyboardOpen = false
    if (!data.disabled) {
      keyboardOpen = screenType.value === 'edit-check' ? false : true
    }

    data['extData']['sheetMusicCreation'] = {
      keyboardOpen,
      svgApi: `${process.env.VUE_APP_PRE_RENDER_SERVICE}/sheet-svg/render-data`,
      scale: scale.value,
      draggableOpt: {
        position: 'absolute',
        scale: scale.value
      }
    }
  } else if (
      elementInfo?._meta?.type === QuestionType.sheetMusicSongPlay ||
      elementInfo?.type === QuestionType.sheetMusicSongPlay
  ) {
    data['extData']['sheetMusicSongPlay'] = {
      svgApi: `${process.env.VUE_APP_PRE_RENDER_SERVICE}/sheet-svg/render-data`,
      scale: scale.value,
      style: {
        top: 'auto',
        left: '12vw',
        bottom: '25px',
        zIndex: 1
      }
    }
  } else if (
      elementInfo?._meta?.type === QuestionType.sheetMusicSongSing ||
      elementInfo?.type === QuestionType.sheetMusicSongSing
  ) {
    data['extData']['sheetMusicSongSing'] = {
      svgApi: `${process.env.VUE_APP_PRE_RENDER_SERVICE}/sheet-svg/render-data`,
      scale: scale.value,
      style: {
        top: 'auto',
        left: '12vw',
        bottom: '25px',
        zIndex: 1
      }
    }
  } else if (
      elementInfo?._meta?.type === QuestionType.sheetMusicSongRegard ||
      elementInfo?.type === QuestionType.sheetMusicSongRegard
  ) {
    data['extData']['sheetMusicSongRegard'] = {
      svgApi: `${process.env.VUE_APP_PRE_RENDER_SERVICE}/sheet-svg/render-data`,
      scale: scale.value,
      style: {
        top: 'auto',
        left: '12vw',
        bottom: '25px',
        zIndex: 1
      }
    }
  } else if (
      elementInfo?._meta?.type === QuestionType.jiasawQuestion ||
      elementInfo?.type === QuestionType.jiasawQuestion
  ) {
    data['extData']['jiasawQuestion'] = {
      scale: scale.value
    }
  } else if (
      props.elementInfo?._meta?.type === QuestionType.dragQuestion ||
      elementInfo?.type === QuestionType.dragQuestion
  ) {
    data['extData']['dragQuestion'] = {
      scale: scale.value
    }
  }
  return data
}

const onLoading = (state: boolean, _loadingTip?: string) => {
  if (state) {
    // 在画布尺寸调整时，显示画布尺寸加载页面，不显示题目加载页面
    if (!store.state.pageLoading && showCurrentLoading.value) {
      loading.value = true
      showLoading('加载题目中...', 3000)
    }
  } else {
    // 仅关闭当前组件的题目加载页面
    if (loading.value) {
      closeLoading()
      loading.value = false
    }
  }
}
const showError = (e: any) => {
  console.error('error', e)
}
const showMessage = (msg: string) => {
  showMeassage(msg)
}
// 重置题目、停止音视频播放
const resetQuestion = () => {
  if (questionRef.value) {
    questionRef.value.stopMedia()
    questionRef.value.resetQuestionAnswer()
  }
}
onMounted(() => {
  emitter.on(EmitterEvents.ON_WEBVIEW_HIDE, resetQuestion)
  emitter.on(EmitterEvents.ON_PPT_HIDE, resetQuestion)
})
onBeforeUnmount(() => {
  emitter.off(EmitterEvents.ON_WEBVIEW_HIDE, resetQuestion)
  emitter.off(EmitterEvents.ON_PPT_HIDE, resetQuestion)
})

return (_ctx: any,_cache: any) => {
  return (props.elementInfo?._meta)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_unref(ExaminationQuestion), _mergeProps({ ref: questionRef }, _unref(questionData), {
          onError: showError,
          onLoading: onLoading,
          onMessage: showMessage
        }), null, 16),
        _withDirectives(_createElementVNode("div", {
          style: _normalizeStyle({ width: `${_unref(width)}px`, height: `${_unref(height)}px` }),
          class: "disable-click"
        }, null, 4), [
          [_vShow, _unref(screenType) === 'edit-check']
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})