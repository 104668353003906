import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-90324dee")
const _hoisted_1 = { class: "chart" }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "chart-content",
      ref: "chartRef",
      style: _normalizeStyle({
        width: _ctx.width + 'px',
        height: _ctx.height + 'px',
        transform: `scale(${1 / _ctx.slideScale})`,
      })
    }, null, 4)
  ]))
}