import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-345e8952")
const _hoisted_1 = {
  key: 0,
  class: "music-rhythm"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MusicRhythm = _resolveComponent("MusicRhythm")!

  return (_ctx.firstLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_MusicRhythm, {
          ref: "musicRhythmRef",
          mode: _ctx.screening ? 'rhythm' : 'disabled',
          "rhythm-data": _ctx.MusicRhythmData,
          onAudioCacheError: _ctx.onAudioCacheError
        }, null, 8, ["mode", "rhythm-data", "onAudioCacheError"])
      ]))
    : _createCommentVNode("", true)
}