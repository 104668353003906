import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-95473d5c")
const _hoisted_1 = {
  ref: "videoRef",
  class: "video-js"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "videoContainerRef",
    class: _normalizeClass([
            'video-container',
            `video-container_${_ctx.elementInfo.id}`,
            'ppt-video-element'
        ])
  }, [
    _createElementVNode("div", _hoisted_1, null, 512)
  ], 2))
}