import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-fbe72fc4")
const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["transform"]
const _hoisted_3 = ["d", "fill", "stroke", "stroke-width", "stroke-dasharray"]
const _hoisted_4 = ["innerHTML"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GradientDefs = _resolveComponent("GradientDefs")!
  const _component_SvgWrapper = _resolveComponent("SvgWrapper")!

  return (_openBlock(), _createElementBlock("div", {
    class: "base-element-shape",
    style: _normalizeStyle({
			top: _ctx.elementInfo.top + 'px',
			left: _ctx.elementInfo.left + 'px',
			width: _ctx.elementInfo.width + 'px',
			height: _ctx.elementInfo.height + 'px'
		})
  }, [
    _createElementVNode("div", {
      class: "rotate-wrapper",
      style: _normalizeStyle({ transform: `rotate(${_ctx.elementInfo.rotate}deg)` })
    }, [
      _createElementVNode("div", {
        class: "element-content",
        style: _normalizeStyle({
					opacity: _ctx.elementInfo.opacity,
					filter: _ctx.shadowStyle ? `drop-shadow(${_ctx.shadowStyle})` : '',
					transform: _ctx.flipStyle,
					color: _ctx.text.defaultColor,
					fontFamily: _ctx.text.defaultFontName
				})
      }, [
        _createVNode(_component_SvgWrapper, {
          overflow: "visible",
          width: _ctx.elementInfo.width,
          height: _ctx.elementInfo.height
        }, {
          default: _withCtx(() => [
            (_ctx.elementInfo.gradient)
              ? (_openBlock(), _createElementBlock("defs", _hoisted_1, [
                  _createVNode(_component_GradientDefs, {
                    id: `base-gradient-${_ctx.elementInfo.id}`,
                    type: _ctx.elementInfo.gradient.type,
                    color1: _ctx.elementInfo.gradient.color[0],
                    color2: _ctx.elementInfo.gradient.color[1],
                    rotate: _ctx.elementInfo.gradient.rotate
                  }, null, 8, ["id", "type", "color1", "color2", "rotate"])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("g", {
              transform: 
							`scale(${_ctx.elementInfo.width / _ctx.viewBox[0]}, ${_ctx.elementInfo.height /
								_ctx.viewBox[1]}) translate(0,0) matrix(1,0,0,1,0,0)`
						
            }, [
              _createElementVNode("path", {
                "vector-effect": "non-scaling-stroke",
                "stroke-linecap": "butt",
                "stroke-miterlimit": "8",
                "stroke-linejoin": "",
                d: _ctx.elementInfo.path,
                fill: 
								_ctx.elementInfo.gradient
									? `url(#base-gradient-${_ctx.elementInfo.id})`
									: _ctx.elementInfo.fill
							,
                stroke: _ctx.outlineColor,
                "stroke-width": _ctx.outlineWidth,
                "stroke-dasharray": _ctx.outlineStyle === 'dashed' ? '10 5' : '0 0'
              }, null, 8, _hoisted_3)
            ], 8, _hoisted_2)
          ]),
          _: 1
        }, 8, ["width", "height"]),
        _createElementVNode("div", {
          class: _normalizeClass(["shape-text", [`shape-text_${_ctx.text.align}`]])
        }, [
          _createElementVNode("div", {
            class: "ProseMirror-static",
            innerHTML: _ctx.text.content
          }, null, 8, _hoisted_4)
        ], 2)
      ], 4)
    ], 4)
  ], 4))
}