
import {
  computed,
  defineComponent,
  inject,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref
} from 'vue'
import { ElfElementTypes, PPTElfMusicRhythmElement } from '@/types/slides'
import MusicRhythm from '@evideo/design-vue/lib/music-rhythm'
import '@evideo/design-vue/lib/music-rhythm/style'
import useShowMessage from '@/hooks/useShowMessage'
import { useStore } from '@/store'
import emitter, { EmitterEvents } from '@/utils/emitter'
import logger from '@evideo/logger'

export default defineComponent({
  name: ElfElementTypes.MUSIC_RHYTHM,
  components: {
    MusicRhythm
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTElfMusicRhythmElement>,
      required: true
    }
  },
  setup(props) {
    const { showMeassage } = useShowMessage()

    const previewSign = inject<boolean>('previewSign')

    const musicRhythmRef = ref<any>(null)
    const store = useStore()
    const firstLoading = ref(false)
    const MusicRhythmData: any = ref({})
    const meta = props.elementInfo._meta! || {}
    const screening = computed(() => store.state.screening)
    const getRhythmData = async () => {
      if (!(meta.json_file_url instanceof Object)) {
        const response = await fetch(meta.json_file_url)
        meta.json_file_url = await response.json()
      }
      MusicRhythmData.value = meta
      firstLoading.value = true
    }


    const onStopPlay = () => {
      musicRhythmRef.value.checkStopPlay()
    }
    const onAudioCacheError = ({ audioCanPlay, preFetchAudioOK }: {
      audioCanPlay: boolean
      preFetchAudioOK: boolean
    }) => {
      if (!audioCanPlay) {
        showMeassage('音频加载失败，请重新加载ppt或检查网络', 'error')
        return
      }
      if (!preFetchAudioOK) {
        showMeassage('音频正在加载，请稍后尝试', 'info')
      }
    }

    const doAction = () => {
      const player = musicRhythmRef.value.musicAudio
      logger.info('audio is playing: ', player?.paused)
      if (player) {
        if (player.paused) {
          musicRhythmRef.value.rhythmPlay()
          logger.info(
              `【调试日志】 音频播放: {name:${meta.name},url:${meta.mp3_file_url}}`
          )
        } else {
          musicRhythmRef.value.rhythmPause()
          logger.info(
              `【调试日志】音频暂停: {name:${meta.name},url:${meta.mp3_file_url}}`
          )
        }
      }
    }

    onMounted(() => {
      getRhythmData()
      if (!previewSign) {
        emitter.on(EmitterEvents.SWITCH_SLIDE_PAGE, onStopPlay)
        emitter.on(EmitterEvents.PPT_STOP_PLAY, onStopPlay)
        emitter.on(EmitterEvents.ON_WEBVIEW_HIDE, onStopPlay)
        emitter.on(EmitterEvents.ON_PPT_HIDE, onStopPlay)
      }

    })

    onBeforeUnmount(() => {
      if (!previewSign) {
        emitter.off(EmitterEvents.SWITCH_SLIDE_PAGE, onStopPlay)
        emitter.off(EmitterEvents.PPT_STOP_PLAY, onStopPlay)
        emitter.off(EmitterEvents.ON_WEBVIEW_HIDE, onStopPlay)
        emitter.off(EmitterEvents.ON_PPT_HIDE, onStopPlay)
      }

    })

    return {
      MusicRhythmData,
      firstLoading,
      screening,
      musicRhythmRef,
      onStopPlay,
      doAction,
      onAudioCacheError
    }
  }
})
