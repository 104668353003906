// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/images/enjoy-song/dialog_enjoy_left_arrow.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/images/enjoy-song/dialog_enjoy_right_arrow.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".background-images-wrapper[data-v-46b2cdc6],.background-images-wrapper .image[data-v-46b2cdc6]{height:100%;width:100%}.background-images-wrapper .image img[data-v-46b2cdc6]{height:100%;width:100%;transition-duration:2s}.background-images-wrapper .switch-button[data-v-46b2cdc6]{height:100%;width:100%;position:absolute;z-index:2;bottom:0;display:flex;justify-content:space-between}.background-images-wrapper .switch-button .hidden[data-v-46b2cdc6]{visibility:hidden}.background-images-wrapper .switch-button .prevpage[data-v-46b2cdc6]{margin-left:1.25%;height:80%;width:2.7%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat;background-size:contain;background-position:bottom;cursor:pointer}.background-images-wrapper .switch-button .nextpage[data-v-46b2cdc6]{margin-right:1.25%;height:80%;width:2.7%;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;background-size:contain;background-position:bottom;cursor:pointer}", ""]);
// Exports
module.exports = exports;
