import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-31a246ce")
const _hoisted_1 = ["d", "stroke", "stroke-width", "stroke-dasharray", "marker-start", "marker-end"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinePointMarker = _resolveComponent("LinePointMarker")!
  const _component_SvgWrapper = _resolveComponent("SvgWrapper")!

  return (_openBlock(), _createElementBlock("div", {
    class: "base-element-shape",
    style: _normalizeStyle({
			top: _ctx.elementInfo.top + 'px',
			left: _ctx.elementInfo.left + 'px'
		})
  }, [
    _createElementVNode("div", {
      class: "element-content",
      style: _normalizeStyle({ filter: _ctx.shadowStyle ? `drop-shadow(${_ctx.shadowStyle})` : '' })
    }, [
      _createVNode(_component_SvgWrapper, {
        overflow: "visible",
        width: _ctx.svgWidth,
        height: _ctx.svgHeight
      }, {
        default: _withCtx(() => [
          _createElementVNode("defs", null, [
            (_ctx.elementInfo.points[0])
              ? (_openBlock(), _createBlock(_component_LinePointMarker, {
                  key: 0,
                  id: _ctx.elementInfo.id,
                  position: "start",
                  type: _ctx.elementInfo.points[0],
                  color: _ctx.elementInfo.color,
                  baseSize: _ctx.elementInfo.width
                }, null, 8, ["id", "type", "color", "baseSize"]))
              : _createCommentVNode("", true),
            (_ctx.elementInfo.points[1])
              ? (_openBlock(), _createBlock(_component_LinePointMarker, {
                  key: 1,
                  id: _ctx.elementInfo.id,
                  position: "end",
                  type: _ctx.elementInfo.points[1],
                  color: _ctx.elementInfo.color,
                  baseSize: _ctx.elementInfo.width
                }, null, 8, ["id", "type", "color", "baseSize"]))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("path", {
            d: _ctx.path,
            stroke: _ctx.elementInfo.color,
            "stroke-width": _ctx.elementInfo.width,
            "stroke-dasharray": _ctx.lineDashArray,
            fill: "none",
            "stroke-linecap": "",
            "stroke-linejoin": "",
            "stroke-miterlimit": "",
            "marker-start": 
						_ctx.elementInfo.points[0]
							? `url(#${_ctx.elementInfo.id}-${_ctx.elementInfo.points[0]}-start)`
							: ''
					,
            "marker-end": 
						_ctx.elementInfo.points[1]
							? `url(#${_ctx.elementInfo.id}-${_ctx.elementInfo.points[1]}-end)`
							: ''
					
          }, null, 8, _hoisted_1)
        ]),
        _: 1
      }, 8, ["width", "height"])
    ], 4)
  ], 4))
}