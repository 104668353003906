
import _ from 'lodash'
import {
	computed,
	defineComponent,
	onMounted,
	onUnmounted,
	PropType,
	ref,
	watch
} from 'vue'
import { MutationTypes, useStore } from '@/store'
import { PPTTextElement } from '@/types/slides'
import { ContextmenuItem } from '@/components/Contextmenu/types'
import useElementShadow from '@/views/components/element/hooks/useElementShadow'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'

import ElementOutline from '@/views/components/element/ElementOutline.vue'
import ProsemirrorEditor from '@/views/components/element/ProsemirrorEditor.vue'

export default defineComponent({
	name: 'editable-element-text',
	components: {
		ElementOutline,
		ProsemirrorEditor
	},
	props: {
		elementInfo: {
			type: Object as PropType<PPTTextElement>,
			required: true
		},
		selectElement: {
			type: Function as PropType<
				(e: MouseEvent, element: PPTTextElement, canMove?: boolean) => void
			>,
			required: true
		},
		contextmenus: {
			type: Function as PropType<() => ContextmenuItem[]>
		}
	},
	setup(props) {
		const store = useStore()
		const { addHistorySnapshot } = useHistorySnapshot()

		const elementRef = ref<HTMLElement>()

		const shadow = computed(() => props.elementInfo.shadow)
		const { shadowStyle } = useElementShadow(shadow)

		const handleElementId = computed(() => store.state.handleElementId)

		const handleSelectElement = (e: MouseEvent, canMove = true) => {
			if (props.elementInfo.lock) return
			e.stopPropagation()

			props.selectElement(e, props.elementInfo, canMove)
		}

		// 监听文本元素的尺寸变化，当高度变化时，更新高度到vuex
		// 如果高度变化时正处在缩放操作中，则等待缩放操作结束后再更新
		const realHeightCache = ref(-1)
		const realWidthCache = ref(-1)

		const isScaling = computed(() => store.state.isScaling)

		watch(isScaling, () => {
			if (handleElementId.value !== props.elementInfo.id) return

			if (!isScaling.value) {
				if (!props.elementInfo.vertical && realHeightCache.value !== -1) {
					store.commit(MutationTypes.UPDATE_ELEMENT, {
						id: props.elementInfo.id,
						props: { height: realHeightCache.value }
					})
					realHeightCache.value = -1
				}
				if (props.elementInfo.vertical && realWidthCache.value !== -1) {
					store.commit(MutationTypes.UPDATE_ELEMENT, {
						id: props.elementInfo.id,
						props: { width: realWidthCache.value }
					})
					realHeightCache.value = -1
				}
			}

			// if (!isScaling.value && realHeightCache.value !== -1) {
			// 	store.commit(MutationTypes.UPDATE_ELEMENT, {
			// 		id: props.elementInfo.id,
			// 		props: { height: realHeightCache.value }
			// 	})
			// 	realHeightCache.value = -1
			// }
		})

		const updateTextElementHeight = (entries: ResizeObserverEntry[]) => {
			const contentRect = entries[0].contentRect
			if (!elementRef.value) return

			const realHeight = contentRect.height + 20
			const realWidth = contentRect.width + 20

			if (
				!props.elementInfo.vertical &&
				props.elementInfo.height !== realHeight
			) {
				if (!isScaling.value) {
					store.commit(MutationTypes.UPDATE_ELEMENT, {
						id: props.elementInfo.id,
						props: { height: realHeight }
					})
				} else realHeightCache.value = realHeight
			}
			if (props.elementInfo.vertical && props.elementInfo.width !== realWidth) {
				if (!isScaling.value) {
					store.commit(MutationTypes.UPDATE_ELEMENT, {
						id: props.elementInfo.id,
						props: { width: realWidth }
					})
				} else realWidthCache.value = realWidth
			}
		}
		const resizeObserver = new ResizeObserver(updateTextElementHeight)

		onMounted(() => {
			if (elementRef.value) resizeObserver.observe(elementRef.value)
		})
		onUnmounted(() => {
			if (elementRef.value) resizeObserver.unobserve(elementRef.value)
		})

		const updateContent = (content: string) => {
			store.commit(MutationTypes.UPDATE_ELEMENT, {
				id: props.elementInfo.id,
				props: { content }
			})

			addHistorySnapshot()
		}
		const checkEmptyText = _.debounce(
			function() {
				const pureText = props.elementInfo.content.replaceAll(/<[^>]+>/g, '')
				if (!pureText) {
					store.commit(MutationTypes.DELETE_ELEMENT, props.elementInfo.id)
				}
			},
			300,
			{ trailing: true }
		)

		const isHandleElement = computed(
			() => handleElementId.value === props.elementInfo.id
		)
		watch(isHandleElement, () => {
			if (!isHandleElement.value) checkEmptyText()
		})
		return {
			elementRef,
			shadowStyle,
			updateContent,
			handleSelectElement
		}
	}
})
