import { ElfElementTypes } from '@/types/slides'
import mainComponent from './main.vue'
import toolbarComponent from './toolbar.vue'

export default {
	initData: {
		subtype: ElfElementTypes.PK_QUESTIONS,
		width: -1,
		height: -1
	},
	config: {
		scaleLock: true // 大小锁定标识
	},
	mainComponent,
	toolbarComponent
}
