
import _ from 'lodash'
import { computed, defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'practice-score',
  props: {
    scorePeople: {
      type: Array,
      default: () => ([])
    }
  },
  emits: ['confirm'],
  setup(props) {
    const stateText = ref('再次演奏')
    const scorePeople = computed(() => _.orderBy(props.scorePeople || [], 'score', 'desc') as any[])
    const topPeople = computed(() => scorePeople.value.slice(0, 3))
    const ortherPeople = computed(() => scorePeople.value.slice(3))
    return {
      stateText,
      topPeople,
      ortherPeople
    }
  },
})
