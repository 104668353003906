import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7e01d5d6")
const _hoisted_1 = { class: "ensemble-practice_Modal practice-modal" }
const _hoisted_2 = { class: "ensemble-practice_Modal score-modal" }
_popScopeId()

import {
  PropType,
  computed,
  ref,
  reactive,
  watch,
  onMounted,
  onBeforeUnmount,
  createVNode,
} from 'vue'
import { PPTElfEnsemblePracticeElement } from '@/types/slides'
import { Modal } from 'ant-design-vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue'
import useAndroid from '@/hooks/useAndroid'
import IssueTask from './components/issue-task.vue'
import PracticeScoreVue from './components/practice-score.vue'
import emitter, { EmitterEvents } from '@/utils/emitter'
import useQianKun from '@/hooks/useQianKun'
import useShowMessage from '@/hooks/useShowMessage'
import logger from '@evideo/logger'


const __default__ = { name: 'ensemble-practice' }

function setup(__props) {

const props = __props

const { document } = useQianKun()
const { showMeassage } = useShowMessage()
const {
  getStudentDevices,
  issureTask,
  beginIssureTask,
  stopIssureTask,
  closeStudentPage,
} = useAndroid()

// 任务弹窗-显隐
const practiceModalVisible = ref(false)

const onPracticeBtnClick = () => {
  practiceModalVisible.value = true
}
// 任务弹窗逻辑
const sheetMusics = computed(() => props.elementInfo.sheetMusics)
const accompanimentUrl = computed(() => props.elementInfo.accompanimentUrl)
const accompanimentSpeed = computed(() => props.elementInfo.accompanimentSpeed)
const accompanimentMd5 = computed(() => props.elementInfo.accompanimentMd5)
const timeSignature = computed(() => props.elementInfo.timeSignature)
const practiceLoading = ref(false)
const practiceLoadingText = ref<string>('')
const tasks = ref<Array<any>>([])
const taskOption = reactive({
  speed: accompanimentSpeed.value || 100,
  accompaniment: true,
  metronome: false,
  midi: false,
})

watch(practiceModalVisible, async (newVal) => {
  if (newVal) {
    const studentDevices = await getStudentDevices()
    tasks.value = studentDevices.map((d) => {
      const source = tasks.value.find((i) => i.sn === d.sn)
      if (source) {
        return Object.assign(source, d)
      }
      return d
    })
  }
})

const issueStatus = ref<'none' | 'start' | 'progress' | 'finish'>('none')

const onIssueSheetMusics = async (data: any) => {
  const { tasksInfo, taskOpt, callBack } = data
  issueStatus.value = 'start'
  // 下发任务 开始任务
  practiceLoading.value = true
  practiceLoadingText.value = '发放中...'
  issueStatus.value = 'progress'
  const taskObj = {
    tasksInfo,
    taskOpt: {
      ...taskOpt,
      accompanimentUrl: accompanimentUrl.value,
      accompanimentSpeed: accompanimentSpeed.value,
      accompanimentMd5: accompanimentMd5.value,
      timeSignature: timeSignature.value,
    },
  }
  logger.info('钢琴集体课发放的数据', taskObj)
  const taskResult = await issureTask(taskObj)
  if (taskResult) {
    practiceLoading.value = false
    practiceLoadingText.value = ''
    issueStatus.value = 'finish'
    callBack()
    showMeassage('发放成功', 'success')
  } else {
    initTask()
    issueStatus.value = 'none'
    ensembleStatus.value = 'none'
    showMeassage('发放失败，请重试', 'warning')
  }
}

const ensembleStatus = ref<'none' | 'start' | 'progress' | 'finish'>('none')
const onEnsembleSheetMusics = async (data: any) => {
  const { ensembleStatus: _ensembleStatus } = data
  if (_ensembleStatus === 'start' || _ensembleStatus === 'progress') {
    stopIssureTask()
    practiceLoading.value = false
    practiceLoadingText.value = ''
    ensembleStatus.value = 'none'
    scoreModalVisible.value = false
    return
  }
  ensembleStatus.value = 'start'
  practiceLoading.value = true
  practiceLoadingText.value = '合奏中...'
  ensembleStatus.value = 'progress'

  scorePeople.value = await beginIssureTask()
  // fixme: 这个数据要真实从安卓返回过来才能进行判断
  if (scorePeople.value.length > 0) {
    ensembleStatus.value = 'finish'
    scoreModalVisible.value = true
    practiceModalVisible.value = false
  } else {
    ensembleStatus.value = 'none'
    scoreModalVisible.value = false
  }
  practiceLoading.value = false
  practiceLoadingText.value = ''
}

// 任务弹窗关闭
const onPracticeModalClose = () => {
  Modal.confirm({
    title: '关闭钢琴集体课',
    icon: createVNode(ExclamationCircleOutlined),
    content: '确定要关闭？关闭会取消合奏，关闭学生页面！',
    cancelText: '取消',
    okText: '确定',
    width: '500px',
    centered: true,
    okButtonProps: {
      size: 'large',
    },
    cancelButtonProps: {
      size: 'large',
    },
    getContainer: () => document.body as HTMLElement,
    onOk() {
      practiceModalVisible.value = false
      resetStatus()
    },
  })
}

// 分数弹窗
const scorePeople = ref<any[]>([])
const scoreModalVisible = ref(false)

const initTask = () => {
  practiceLoading.value = false
  practiceLoadingText.value = ''
  scorePeople.value = []
}

const resetStatus = () => {
  practiceLoading.value = false
  practiceLoadingText.value = ''
  scoreModalVisible.value = false
  scorePeople.value = []
  issueStatus.value = 'none'
  ensembleStatus.value = 'none'
  stopIssureTask()
  closeStudentPage()
}

onMounted(() => {
  emitter.on(EmitterEvents.PPT_STOP_PLAY, resetStatus)
})
onBeforeUnmount(() => {
  resetStatus()
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", { class: "ensemble-practice" }, [
      _createElementVNode("div", {
        class: "practice-btn",
        onClick: onPracticeBtnClick
      })
    ]),
    _createVNode(_unref(Modal), {
      visible: practiceModalVisible.value,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (practiceModalVisible.value = $event)),
      footer: null,
      centered: "",
      closable: false,
      maskClosable: false,
      width: 1211
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(IssueTask, {
            class: "modal-content",
            "sheet-musics": _unref(sheetMusics),
            loading: practiceLoading.value,
            "loading-text": practiceLoadingText.value,
            tasks: tasks.value,
            "task-option": _unref(taskOption),
            "issue-status": issueStatus.value,
            "ensemble-status": ensembleStatus.value,
            "has-acc": !!_unref(accompanimentUrl),
            onIssueSheetMusics: onIssueSheetMusics,
            onEnsembleSheetMusics: onEnsembleSheetMusics
          }, null, 8, ["sheet-musics", "loading", "loading-text", "tasks", "task-option", "issue-status", "ensemble-status", "has-acc"]),
          _createElementVNode("div", {
            class: "modal-btn-close",
            onClick: onPracticeModalClose
          }, "退出弹奏"),
          _createElementVNode("div", {
            class: "modal-btn-min",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (practiceModalVisible.value = false))
          }, "收起")
        ])
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_unref(Modal), {
      visible: scoreModalVisible.value,
      "onUpdate:visible": _cache[4] || (_cache[4] = ($event: any) => (scoreModalVisible.value = $event)),
      footer: null,
      centered: "",
      closable: false,
      width: 914,
      maskClosable: false
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(PracticeScoreVue, {
            class: "modal-content",
            "score-people": scorePeople.value,
            onConfirm: _cache[2] || (_cache[2] = () => { initTask(), scoreModalVisible.value = false, practiceModalVisible.value = true })
          }, null, 8, ["score-people"]),
          _createElementVNode("div", {
            class: "modal-close",
            onClick: _cache[3] || (_cache[3] = ($event: any) => (scoreModalVisible.value = false))
          })
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}


export default _defineComponent({
  ...__default__,
  props: {
  elementInfo: {
    type: Object as PropType<PPTElfEnsemblePracticeElement>,
    required: true,
  },
  inScreening: Boolean,
},
  setup})