
import { computed, defineComponent, onMounted, ref } from 'vue'
import _ from 'lodash'
import { MutationTypes, useStore } from '@/store'
import { PPTElfPKQuestionsElement } from '@/types/slides'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'
import useUpLoadResource from '@/hooks/useUpLoadResource'
import { Switch, message } from 'ant-design-vue'
import { PptState } from '@/types/pptState'

export default defineComponent({
	name: 'pk-question-style-panel',
	components: { 'a-switch': Switch },
	setup() {
		const store = useStore()
		const {
			getImageResourceUrl,
			createOssResource,
			getResourceList
		} = useUpLoadResource()
		const { addHistorySnapshot } = useHistorySnapshot()
		const handleElement = computed<PPTElfPKQuestionsElement>(
			() => store.getters.handleElement
		)
		const backgroundImages = ref<
			{ url: string; label: string; from: string }[]
		>([])
		const isChangedBackground = ref(false)
		const isChangeMelodyImage = ref(false)
		const currentSelectedBackgroundImage = ref(
			handleElement.value?.backgroundImage
		)
		const aiReading = ref(
			handleElement.value?.questionSetting?.aIEnable === undefined ||
				handleElement.value?.questionSetting?.aIEnable === true
				? true
				: false
		)
		const singleMode = ref(
			handleElement.value?.questionSetting?.mode === 'single'
		)

		const updateElementInfo = _.throttle(
			() => {
				const props = {
					backgroundImage: currentSelectedBackgroundImage.value
				}
				store.commit(MutationTypes.UPDATE_ELEMENT, {
					id: handleElement.value.id,
					props
				})
				store.commit(MutationTypes.SET_PPT_STATE, PptState.MODIFY)
				setTimeout(() => {
					addHistorySnapshot()
				}, 200)
				return ''
			},
			100,
			{ leading: true, trailing: false }
		)

		const handleDropdownVisibleChange = (dropdownVisible: boolean) => {
			if (isChangedBackground.value || isChangeMelodyImage.value) {
				!dropdownVisible && updateElementInfo()
			}
			isChangedBackground.value = false
			isChangeMelodyImage.value = false
		}

		const resetBackgroundImage = () => {
			currentSelectedBackgroundImage.value = ''
		}

		const naturalSort = (a: any, b: any) => {
			const regex = /(\d+)|(\D+)/g
			const digitsOnly = /^\d+$/
			const aParts = a.name.match(regex)
			const bParts = b.name.match(regex)
			for (let i = 0; i < Math.min(aParts.length, bParts.length); i++) {
				const aPart = aParts[i]
				const bPart = bParts[i]
				if (digitsOnly.test(aPart) && digitsOnly.test(bPart)) {
					const aInt = parseInt(aPart, 10)
					const bInt = parseInt(bPart, 10)
					if (aInt !== bInt) {
						return aInt - bInt
					}
				} else if (aPart !== bPart) {
					return aPart.localeCompare(bPart)
				}
			}
			return aParts.length - bParts.length
		}

		const initBackgroundImages = () => {
			backgroundImages.value.length = 0
			return new Promise((resolve, reject) => {
				getResourceList('ppt_resource/elf_pk_questions/background/')
					.then((listData: any) => {
						const { objects } = listData || {}
						objects.sort(naturalSort)
						if (objects && objects.length > 0) {
							objects.forEach((data: any) => {
								const { url, size } = data || {}
								size &&
									backgroundImages.value.push({
										label: '',
										url,
										from: 'oss-resource'
									})
							})
						}
						if (!currentSelectedBackgroundImage.value) {
							currentSelectedBackgroundImage.value =
								backgroundImages.value[0].url
						}
						resolve('')
					})
					.catch((err) => reject(err))
			})
		}

		const changeBackgroundSelect = (selectedImage: {
			label: string
			url: string
		}) => {
			currentSelectedBackgroundImage.value = selectedImage.url
			isChangedBackground.value = true
		}

		const uploadBackgroundImage = async (files: File[]) => {
			const imgFile = files[0]
			if (!imgFile) {
				return
			}
			if (imgFile?.size > 2 * 1024 * 1024) {
				message.warning('请选择2M以内的图片')
				return
			}
			try {
				const res = await getImageResourceUrl(imgFile)
				resetBackgroundImage()
				currentSelectedBackgroundImage.value = res.url
				updateElementInfo()
			} catch (err) {
				message.error('图片上传失败')
			}
		}

		const handleAiReadingChange = () => {
			const props = {
				questionSetting: {
					aIEnable: aiReading.value,
					mode: singleMode.value === true ? 'single' : 'pk'
				}
			}
			store.commit(MutationTypes.UPDATE_ELEMENT, {
				id: handleElement.value.id,
				props
			})
			store.commit(MutationTypes.SET_PPT_STATE, PptState.MODIFY)
		}

		const handleSingleModeChange = () => {
			const props = {
				questionSetting: {
					aIEnable: aiReading.value,
					mode: singleMode.value === true ? 'single' : 'pk'
				}
			}
			store.commit(MutationTypes.UPDATE_ELEMENT, {
				id: handleElement.value.id,
				props
			})
			store.commit(MutationTypes.SET_PPT_STATE, PptState.MODIFY)
		}

		onMounted(async () => {
			try {
				await createOssResource()
				await initBackgroundImages()
			} catch (err) {
				message.error('初始化答题PK右侧编辑失败')
			}
		})

		return {
			backgroundImages,
			currentSelectedBackgroundImage,
			aiReading,
			singleMode,
			handleDropdownVisibleChange,
			initBackgroundImages,
			changeBackgroundSelect,
			uploadBackgroundImage,
			handleAiReadingChange,
			handleSingleModeChange
		}
	}
})
