import { ElfElementTypes, Slide } from '@/types/slides'
import Audio from './Audio'
import CarouseImage from './CarouseImage'
import EnjoySong from './EnjoySong'
import EnsemblePractice from './EnsemblePractice'
import MicroAppElement from './MicroAppElement'
import MusicRhythm from './MusicRhythm'
import Question from './Question'
import SingPlay from './SingPlay'
import Video from './Video'
import PKQuestions from './PKQuestions'

import { store } from '@/store'
import logger from '@evideo/logger'

export interface ElfConfig {
	initData: {
		subtype: ElfElementTypes
		width: number
		height: number
	}
	config?: {
		editPreview?: boolean
		focusOrder?: number
		scaleLock?: boolean // 大小锁定标识
	}
	delegateFor?: ElfElementTypes[]
	mainComponent: any
	toolbarComponent?: any
}

const elfs: ElfConfig[] = [
	Audio,
	Video,
	CarouseImage,
	EnjoySong,
	SingPlay,
	EnsemblePractice,
	MicroAppElement,
	MusicRhythm,
	Question,
	PKQuestions
]

// elefELemnt 检查者
interface ElfElementChecker {
	[key: string]: {
		msg: string
		check: () => boolean
	}
}

export const elfElementOnlyShowOne: string[] = [
	ElfElementTypes.SING_PLAY,
	ElfElementTypes.MUSIC_RHYTHM,
	ElfElementTypes.QUESTIONS_BANK,
	ElfElementTypes.PK_QUESTIONS
]
export const elfElementChecker: ElfElementChecker = {
	[ElfElementTypes.SING_PLAY]: {
		msg: '一个页面只能存在一个谱例歌曲',
		check: () => {
			const slide = store.getters.currentSlide as Slide
			const elements = slide?.elements || []
			const count = (
				elements.filter(
					(i) => i.type === 'elf' && i.subtype === ElfElementTypes.SING_PLAY
				) || []
			).length
			if (count >= 1) {
				return false
			}
			return true
		}
	},
	[ElfElementTypes.MUSIC_RHYTHM]: {
		msg: '一个页面只能存在一个音乐律动',
		check: () => {
			const slide = store.getters.currentSlide as Slide
			const elements = slide?.elements || []
			const count = (
				elements.filter(
					(i) => i.type === 'elf' && i.subtype === ElfElementTypes.MUSIC_RHYTHM
				) || []
			).length
			if (count >= 1) {
				return false
			}
			return true
		}
	},
	[ElfElementTypes.QUESTIONS_BANK]: {
		msg: '一个页面只能存在一个题目',
		check: () => {
			const slide = store.getters.currentSlide as Slide
			const elements = slide?.elements || []
			const count = (
				elements.filter(
					(i) =>
						i.type === 'elf' && i.subtype === ElfElementTypes.QUESTIONS_BANK
				) || []
			).length
			if (count >= 1) {
				return false
			}
			return true
		}
	},
	[ElfElementTypes.PK_QUESTIONS]: {
		msg: '一个页面只能存在一个答题PK',
		check: () => {
			const slide = store.getters.currentSlide as Slide
			const elements = slide?.elements || []
			const count = (
				elements.filter(
					(i) => i.type === 'elf' && i.subtype === ElfElementTypes.PK_QUESTIONS
				) || []
			).length
			if (count >= 1) {
				return false
			}
			return true
		}
	}
}

export function findElfByType(type: string): ElfConfig | null {
	let ret = elfs.find((e) => {
		const subtype = e.initData.subtype
		if (subtype === type) {
			return true
		}

		if (
			e.delegateFor instanceof Array &&
			e.delegateFor.indexOf(type as ElfElementTypes) >= 0
		) {
			return true
		}

		return false
	})
	if (!ret) {
		logger.warn(type + ' not registered')
	}

	if (ret?.delegateFor !== undefined) {
		ret = Object.assign({}, ret)
		ret.initData.subtype = type as ElfElementTypes
	}

	return ret || null
}
