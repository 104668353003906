
import {
	defineComponent,
	ref,
	reactive,
	onBeforeMount,
	computed,
	watch,
	onMounted,
	toRaw
} from 'vue'
import {
	cursorMap,
	ImageType,
	BackgroundImageMap,
	MelodyCursorImageMap,
	DisplayMode
} from './singplayMap'
import { MutationTypes, useStore } from '@/store'
import { PPTElfSongElement } from '@/types/slides'
import useUpLoadResource from '@/hooks/useUpLoadResource'
import useHistorySnapshot from '@/hooks/useHistorySnapshot'
import ColorButton from '@/views/Editor/Toolbar/common/ColorButton.vue'
import PreviewSheetMusicSong from './previewSheetMusicSong.vue'
import logger from '@evideo/logger'
import { message } from 'ant-design-vue'
import { QuestionCircleOutlined } from '@ant-design/icons-vue'
import _ from 'lodash'

export default defineComponent({
	name: 'sing-play-style-panel',
	components: {
		ColorButton,
		QuestionCircleOutlined,
		PreviewSheetMusicSong
	},
	setup() {
		const store = useStore()
		const {
			getImageResourceUrl,
			createOssResource,
			getResourceList
		} = useUpLoadResource()
		const { addHistorySnapshot } = useHistorySnapshot()
		const handleElement = computed<PPTElfSongElement>(
			() => store.getters.handleElement
		)
		const params = {
			backgroundColorDefault: '',
			lineColorDefault: '#8c6ffb',
			lineWidth: 3
		}
		const ImageModel = {
			url: '',
			value: ''
		}
		const melodyImages = ref<{ url: string; value: string; from: string }[]>([])
		const backgroundImages = ref<
			{ url: string; label: string; from: string }[]
		>([])

		const melodyWidths = [1, 3, 5, 7, 9]
		const isChangedBackground = ref(false)
		const isChangeMelodyImage = ref(false)
		const isChangeMelodyLine = ref(false)

		const isOpenPreview = ref(false)
		const previewVideoVisible = ref(false)
		/** 是否为嵌入组件 */
		const isInsetElement = computed(
			() => handleElement.value?.displayMode === DisplayMode.INSET
		)
		/** 是否为创编组件 */
		const isSheetMusicCreation = computed(
			() => handleElement.value?.displayMode === DisplayMode.CREATE
		)
		/** 是否启用嵌入播放开关 */
		const usePlaySwitch = ref(
			handleElement.value?.useInsetPlayControl === false ? false : true
		)
		/** 当前曲谱组件显示模式 */
		const newDisplayMode = ref(
			handleElement.value?.displayMode || DisplayMode.FULL
		)

		// 背景参数
		const currentSelectedBackgroundImage = reactive<{
			label: string
			url: string
		}>(Object.assign({}, { url: '', label: '' }))
		const backgroundColor = ref<string>(params.backgroundColorDefault)
		watch(backgroundColor, (newValue) => {
			if (
				newValue !== handleElement.value?.songConfig?.background?.color &&
				newValue
			) {
				resetBackgroundImage()
				updateElementInfo()
			}
		})

		// 旋律线参数
		const lineColor = ref<string>(params.lineColorDefault)
		const lineWidth = ref<number>(3)
		const currentSelectedCursorImage = reactive<ImageType>(
			Object.assign({}, ImageModel)
		)
		watch(lineColor, () => {
			if (
				lineColor.value !==
				handleElement?.value?.sheetConfig?.colvinMelody?.melodyLineColor
			) {
				updateElementInfo()
			}
		})

		const initCursorArr = () => {
			melodyImages.value.length = 0
			Object.keys(MelodyCursorImageMap).forEach((melodyImgKey) => {
				melodyImages.value.push({
					value: melodyImgKey,
					url: MelodyCursorImageMap[melodyImgKey],
					from: 'local'
				})
			})
			return new Promise((resolve, reject) => {
				getResourceList('ppt_resource/elf_sing_play/melody_cursor/')
					.then((listData: any) => {
						const { objects } = listData || {}
						if (objects && objects.length > 0) {
							objects.forEach((data: any) => {
								const { url, size } = data || {}
								size &&
									melodyImages.value.push({
										value: '',
										url,
										from: 'oss-resource'
									})
							})
						}
						resolve('')
					})
					.catch((err) => reject(err))
			})
		}

		// 从handleElement 获取参数
		const initBackgroundAndCursorParams = () => {
			const background = {
				url:
					handleElement.value.songConfig?.background?.imageUrl ||
					(handleElement.value.songConfig?.background?.color ? '' : 'bg1'),
				color: handleElement.value.songConfig?.background?.color
			}
			const line = {
				url: handleElement.value.sheetConfig?.colvinMelody?.melodyLineImage,
				color: handleElement.value.sheetConfig?.colvinMelody?.melodyLineColor,
				width: handleElement.value.sheetConfig?.colvinMelody?.melodyLineWidth
			}

			background.color
				? (backgroundColor.value = background.color)
				: (backgroundColor.value = params.backgroundColorDefault)

			if (background.url) {
				if (
					BackgroundImageMap[background.url] ||
					background.url === 'default'
				) {
					currentSelectedBackgroundImage.url =
						BackgroundImageMap[background.url]
					currentSelectedBackgroundImage.label = background.url
				} else {
					currentSelectedBackgroundImage.url = background.url
				}
			} else {
				currentSelectedBackgroundImage.url = ''
			}

			line.color
				? (lineColor.value = line.color)
				: (lineColor.value = params.lineColorDefault)

			line.width
				? (lineWidth.value = line.width)
				: (lineWidth.value = params.lineWidth)

			if (line.url || line.url === 'default') {
				if (MelodyCursorImageMap[line.url]) {
					currentSelectedCursorImage.url = MelodyCursorImageMap[line.url]
					currentSelectedCursorImage.value = line.url
				} else {
					currentSelectedCursorImage.url = line.url
				}
			} else {
				currentSelectedCursorImage.url = cursorMap[0].url
				currentSelectedCursorImage.value = cursorMap[0].value
			}
		}

		const handleChangeLineWidth = (width: number) => {
			lineWidth.value = width
			isChangeMelodyLine.value = true
		}

		// 设置背景图片
		const changeBackgroundSelect = (selectedImage: {
			label: string
			url: string
		}) => {
			currentSelectedBackgroundImage.url = selectedImage.url
			currentSelectedBackgroundImage.label = selectedImage.label
			backgroundColor.value = params.backgroundColorDefault

			isChangedBackground.value = true
		}
		const uploadBackgroundImage = async (files: File[]) => {
			const imgFile = files[0]
			if (!imgFile) {
				return
			}

			try {
				const res = await getImageResourceUrl(imgFile)
				backgroundColor.value = params.backgroundColorDefault
				resetBackgroundImage()
				currentSelectedBackgroundImage.url = res.url
				updateElementInfo()
			} catch (e) {
				logger.error(e)
				message.error('图片上传失败')
			}
		}
		const resetBackgroundImage = () => {
			currentSelectedBackgroundImage.url = ''
			currentSelectedBackgroundImage.label = ''
		}

		// 设置光标图片
		const changeCursorSelect = (selectedImage: ImageType) => {
			currentSelectedCursorImage.url = selectedImage.url
			currentSelectedCursorImage.value = selectedImage.value
			isChangeMelodyImage.value = true
		}
		const uploadCursorImage = async (files: File[]) => {
			const imgFile = files[0]
			if (!imgFile) {
				return
			}

			try {
				const res = await getImageResourceUrl(imgFile)
				resetCursorImage()
				currentSelectedCursorImage.url = res.url
				updateElementInfo()
			} catch (e) {
				logger.error(e)
				message.error('图片上传失败')
			}
		}
		const resetCursorImage = () => {
			currentSelectedCursorImage.url = ''
			currentSelectedCursorImage.value = ''
		}

		const handleDropdownVisibleChange = (dropdownVisible: boolean) => {
			if (
				isChangedBackground.value ||
				isChangeMelodyImage.value ||
				isChangeMelodyLine.value
			) {
				!dropdownVisible && updateElementInfo()
			}
			isChangedBackground.value = false
			isChangeMelodyImage.value = false
			isChangeMelodyLine.value = false
		}

		// 更新信息
		const updateElementInfo = _.throttle(
			() => {
				const props = {
					songConfig: {
						background: {
							color: backgroundColor.value,
							imageUrl: currentSelectedBackgroundImage.label
								? currentSelectedBackgroundImage.label
								: currentSelectedBackgroundImage.url
						}
					},
					sheetConfig: {
						colvinMelody: {
							melodyLineColor: lineColor.value,
							melodyLineWidth: lineWidth.value,
							melodyLineImage: currentSelectedCursorImage.value
								? currentSelectedCursorImage.value
								: currentSelectedCursorImage.url
						}
					}
				}
				store.commit(MutationTypes.UPDATE_ELEMENT, {
					id: handleElement.value.id,
					props
				})

				setTimeout(() => {
					addHistorySnapshot()
				}, 200)
				return ''
			},
			100,
			{ leading: true, trailing: false }
		)

		const initBackgroundImages = () => {
			backgroundImages.value.length = 0
			Object.keys(BackgroundImageMap).forEach((imageKey: string) => {
				backgroundImages.value.push(
					toRaw({
						label: imageKey,
						url: BackgroundImageMap[imageKey],
						from: 'local'
					})
				)
			})
			return new Promise((resolve, reject) => {
				getResourceList('ppt_resource/elf_sing_play/background/')
					.then((listData: any) => {
						const { objects } = listData || {}
						if (objects && objects.length > 0) {
							objects.forEach((data: any) => {
								const { url, size } = data || {}
								size &&
									backgroundImages.value.push({
										label: '',
										url,
										from: 'oss-resource'
									})
							})
						}
						resolve('')
					})
					.catch((err) => reject(err))
			})
		}

		onBeforeMount(() => {
			initBackgroundAndCursorParams()
		})

		onMounted(async () => {
			try {
				await createOssResource()
				await initBackgroundImages()
				await initCursorArr()
			} catch (err) {
				console.error('初始化曲谱右侧编辑失败:', err)
			}
		})

		const changeIsOpenPreview = (state: boolean) => {
			isOpenPreview.value = state
		}

		const changePreviewVideoVisible = (state: boolean) => {
			previewVideoVisible.value = state
		}
		/** 监听曲谱显示模式改变，更新曲谱信息 */
		const handleChangeDisplayMode = (value: string) => {
			store.commit(MutationTypes.UPDATE_ELEMENT, {
				id: handleElement.value.id,
				props: {
					displayMode: value,
					left: 0,
					top: 0,
					width: (value === DisplayMode.FULL) || (value === DisplayMode.CREATE) ? 1920 : 1000,
					height: (value === DisplayMode.FULL) || (value === DisplayMode.CREATE) ? 1080 : 400,
					useInsetPlayControl: usePlaySwitch.value
				}
			})
		}
		/** 监听嵌入组件播放开关 */
		watch(
			() => usePlaySwitch.value,
			_.debounce(() => {
				store.commit(MutationTypes.UPDATE_ELEMENT, {
					id: handleElement.value.id,
					props: {
						useInsetPlayControl: usePlaySwitch.value
					}
				})
			}, 100)
		)

		return {
			currentSelectedBackgroundImage,
			currentSelectedCursorImage,
			lineColor,
			lineWidth,
			backgroundColor,
			params,
			melodyWidths,
			melodyImages,
			backgroundImages,
			isOpenPreview,
			previewVideoVisible,
			DisplayMode,
			newDisplayMode,
			isInsetElement,
			isSheetMusicCreation,
			usePlaySwitch,
			handleChangeDisplayMode,
			handleChangeLineWidth,
			changeBackgroundSelect,
			changeCursorSelect,
			uploadBackgroundImage,
			uploadCursorImage,
			handleDropdownVisibleChange,
			changeIsOpenPreview,
			changePreviewVideoVisible
		}
	}
})
