import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-45bcebee")
const _hoisted_1 = { style: {"width":"auto","height":"auto"} }
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Audio = _resolveComponent("Audio")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Audio, {
      ref: "audioRef",
      src: _ctx.elementInfo.src,
      clipStart: _ctx.elementInfo.clipStart,
      clipEnd: _ctx.elementInfo.clipEnd,
      "no-download": _ctx.previewSign,
      width: _ctx.elementInfo.width,
      onShowAudioInfo: _ctx.onShowAudioInfo,
      onGetAudioDuration: _ctx.getAudioDuration
    }, null, 8, ["src", "clipStart", "clipEnd", "no-download", "width", "onShowAudioInfo", "onGetAudioDuration"])
  ]))
}