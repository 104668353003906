
import { PropType, defineComponent, toRef } from 'vue'
import { PPTElementOutline } from '@/types/slides'
import useElementOutline from '@/views/components/element/hooks/useElementOutline'

export default defineComponent({
  name: 'image-polygon-outline',
  props: {
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    outline: {
      type: Object as PropType<PPTElementOutline>
    },
    createPath: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      outlineWidth,
      outlineStyle,
      outlineColor,
    } = useElementOutline(toRef(props, 'outline'))

    return {
      outlineWidth,
      outlineStyle,
      outlineColor,
    }
  },
})
