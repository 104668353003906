import { ElfElementTypes } from '@/types/slides'
import mainComponent from './main.vue'
import toolbarComponent from './toolbar.vue'

export default {
	initData: {
		subtype: ElfElementTypes.VIDEO,
		width: -1,
		height: -1,
		src: ''
	},
	config: {
		editPreview: true,
		focusOrder: 1
	},
	mainComponent,
	toolbarComponent
}