import { ElfElementTypes } from '@/types/slides'
import mainComponent from './main.vue'
import toolbarComponent from './toolbar.vue'

export default {
    initData: {
        subtype: ElfElementTypes.AUDIO,
        width: 180,
        height: 90,
        src: ''
    },
    config: {
        editPreview: true,
        scaleLock: true, // 大小锁定标识
        focusOrder: 1
    },
    mainComponent,
    toolbarComponent
}
