import { openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-0c8f5d18")
const _hoisted_1 = ["contenteditable", "innerHTML"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "custom-textarea",
    ref: "textareaRef",
    contenteditable: _ctx.contenteditable,
    onFocus: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleFocus && _ctx.handleFocus(...args))),
    onBlur: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
    onInput: _cache[2] || (_cache[2] = $event => _ctx.handleInput($event)),
    innerHTML: _ctx.text
  }, null, 40, _hoisted_1))
}