
import BScroll from '@better-scroll/core'
import { PPTElfEnjoyElement, Slide } from '@/types/slides'
import {
  computed,
  defineComponent,
  inject,
  onBeforeUnmount,
  onMounted,
  PropType,
  ref,
  watch
} from 'vue'
import ParaButton from './ParaButton.vue'
import emitter, { EmitterEvents } from '@/utils/emitter'
import { getTextWidth } from '@/utils/element'
import logger from '@evideo/logger'
import { store } from '@/store'

export default defineComponent({
  name: 'playback-controls',
  components: {
    ParaButton
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTElfEnjoyElement>,
      required: true
    }
  },
  setup(props) {
    const previewSign = inject<boolean>('previewSign')

    const meta = ref(props.elementInfo._meta!)
    if (meta.value.mp3_file_config.length === 0) {
      return {}
    }
    const audioRef = ref<HTMLAudioElement>()
    const isPlay = ref(false)
    const currentSlide = computed<Slide>(() => store.getters.currentSlide)
    const canUseSelect = ref(true)

    // 曲目切换控制
    const currentSongIndex = ref(0)
    const currentSong = computed(
        () => meta.value.mp3_file_config[currentSongIndex.value]
    )
    watch(currentSongIndex, () => {
      const player = audioRef.value
      if (player) {
        player.src = `${currentSong.value.mp3_file_url}?X-Use-Static-Proxy=false`
        isPlay.value = false
      }
    })

    watch(
        () => currentSlide.value,
        () => {
          const isExit = currentSlide.value?.elements.find(
              (el) => el.id === props.elementInfo.id
          )
          if (isExit) {
            canUseSelect.value = false
          } else {
            canUseSelect.value = true
          }
        },
        { immediate: true }
    )

    // 当前歌曲播控
    const curTimeRef = ref(0)
    const duration = ref(100)

    const parseTime = (time: number): string => {
      time = Math.floor(time)
      let minutes: number | string = Math.floor(time / 60)
      let secondes: number | string = time % 60
      secondes = secondes < 10 ? '0' + Math.round(secondes) : Math.round(secondes)
      minutes = minutes < 10 ? '0' + minutes : minutes
      return minutes + ':' + secondes
    }

    const formatTime = computed(
      () => parseTime(curTimeRef.value) + ' / ' + parseTime(duration.value)
    )

    const onSetTime = (e: any) => {
      const player = audioRef.value
      if (player) {
        player.currentTime = parseInt(e.target.value)
      }
    }

    const onPlay = () => {
      const player = audioRef.value
      // console.log('audioRef==>', audioRef)

      if (player) {
        if (player.paused) {
          player.play()
          isPlay.value = true
        } else {
          player.pause()
          isPlay.value = false
        }
      }
    }

    const onPause = () => {
      const player = audioRef.value
      if (player) {
        player.pause()
        isPlay.value = false
        logger.info(`【调试日志】欣赏歌曲暂停`)
      }
    }

    const stopPlay = () => {
      const player = audioRef.value
      player && player.pause()
      isPlay.value = false
      player && (player.currentTime = 0)
      curTimeRef.value = 0
    }

    const scroll = ref<any>()
    const scrollEl = ref<HTMLDivElement>()

    const doAction = () => {
      onPlay()
    }
    onMounted(() => {
      if (previewSign) {
        return
      }
      scroll.value = new BScroll(scrollEl.value!, {
        scrollX: true,
        click: true,
        disableMouse: false
      })
      const player = audioRef.value
      if (player) {
        player.src = `${currentSong.value.mp3_file_url}?X-Use-Static-Proxy=false`

        player.addEventListener('durationchange', () => {
          duration.value = player.duration
        })

        player.addEventListener('timeupdate', () => {
          curTimeRef.value = player.currentTime
          updateCurParaBtnIndexByTime(player.currentTime)
        })

        player.addEventListener('ended', () => {
          isPlay.value = false
          curTimeRef.value = 0
          curParaBtnIndex.value = 0
        })
      }
      emitter.on(EmitterEvents.SWITCH_SLIDE_PAGE, stopPlay)
      emitter.on(EmitterEvents.PPT_STOP_PLAY, stopPlay)
      emitter.on(EmitterEvents.ON_WEBVIEW_HIDE, onPause)
      emitter.on(EmitterEvents.ON_PPT_HIDE, onPause)
    })

    onBeforeUnmount(() => {
      if (previewSign) {
        return
      }
      const player = audioRef.value
      player?.pause()
      emitter.off(EmitterEvents.SWITCH_SLIDE_PAGE, stopPlay)
      emitter.off(EmitterEvents.PPT_STOP_PLAY, stopPlay)
      emitter.off(EmitterEvents.ON_WEBVIEW_HIDE, onPause)
      emitter.off(EmitterEvents.ON_PPT_HIDE, onPause)
      scroll.value && scroll.value.destroy()
    })

    // 分段按钮
    const curParaBtnIndex = ref(0)
    const para_info = computed(() => {
      const para_info = currentSong.value.para_info || []
      return para_info.map((item) => {
        return {
          ...item,
          width: getTextWidth(
              item.name,
              {
                fontSize: '22px',
                display: 'inline-block',
                margin: '3px 15px',
                padding: '0 10px'
              },
              10
          ),
          el: ref()
        }
      })
    })

    watch(
        () => curParaBtnIndex.value,
        (newVal) => {
          const para = para_info.value[newVal]
          if (!para) {
            return
          }
          scroll.value &&
          scroll.value.scrollToElement(para.el.value.$el, 300, true)
        }
    )
    const onParaClicked = (index: number): void => {
      curParaBtnIndex.value = index
      const para = para_info.value[index]!
      const player = audioRef.value
      if (player) {
        player.currentTime = para.start_time
        !isPlay.value && player.play() && (isPlay.value = true)
      }
    }
    const updateCurParaBtnIndexByTime = (time: number) => {
      if (para_info.value.length === 0) {
        return
      }
      const reversed = para_info.value.slice().reverse()
      const index = reversed.findIndex((para) => time >= para.start_time)
      curParaBtnIndex.value = reversed.length - 1 - index
    }

    return {
      meta,
      audioRef,
      isPlay,
      onPlay,
      onSetTime,
      currentSongIndex,
      curTimeRef,
      duration,
      formatTime,
      onParaClicked,
      para_info,
      curParaBtnIndex,
      scrollEl,
      doAction,
      canUseSelect
    }
  }
})
