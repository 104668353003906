
import { defineComponent, PropType, ref } from "vue";
import { ElfElementTypes, PPTElfEnjoyElement } from "@/types/slides";
import SlideShow from "./components/SlideShow.vue";
import PlaybackControls from "./components/PlaybackControls.vue";

export default defineComponent({
  name: ElfElementTypes.ENJOY,
  components: {
    SlideShow,
    PlaybackControls,
  },
  props: {
    elementInfo: {
      type: Object as PropType<PPTElfEnjoyElement>,
      required: true,
    },
  },
  setup(props) {
    const meta = props.elementInfo._meta! || {};
    const getTextLen = (str = "") => {
      const dom = document.createElement("span");
      dom.style.fontSize = "30px";
      dom.style.fontWeight = "bold";
      dom.style.display = "inline-block";
      dom.textContent = str;
      document.body.appendChild(dom);
      const width = dom.clientWidth;
      document.body.removeChild(dom);
      return width + 150 < 350 ? 350 : width + 150;
    };
    const titleLen = ref<any>(300);
    titleLen.value = getTextLen(meta.name || "");

    const playbackControlsRef = ref<any>(null);
    const doAction = () => {
      playbackControlsRef.value?.doAction();
    };


    return {
      doAction,
      meta,
      titleLen,
      playbackControlsRef,
    };
  },
});
