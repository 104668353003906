import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-ee5263bc")
const _hoisted_1 = ["innerHTML"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElementOutline = _resolveComponent("ElementOutline")!

  return (_openBlock(), _createElementBlock("div", {
    class: "base-element-text",
    style: _normalizeStyle({
			top: _ctx.elementInfo.top + 'px',
			left: _ctx.elementInfo.left + 'px',
			width: _ctx.elementInfo.width + 'px'
		})
  }, [
    _createElementVNode("div", {
      class: "rotate-wrapper",
      style: _normalizeStyle({ transform: `rotate(${_ctx.elementInfo.rotate}deg)` })
    }, [
      _createElementVNode("div", {
        class: "element-content",
        style: _normalizeStyle({
					width: _ctx.elementInfo.vertical ? 'auto' : _ctx.elementInfo.width + 'px',
					height: _ctx.elementInfo.vertical ? _ctx.elementInfo.height + 'px' : 'auto',
					backgroundColor: _ctx.elementInfo.fill,
					opacity: _ctx.elementInfo.opacity,
					textShadow: _ctx.shadowStyle,
					lineHeight: _ctx.elementInfo.lineHeight,
					letterSpacing: (_ctx.elementInfo.wordSpace || 0) + 'px',
					color: _ctx.elementInfo.defaultColor,
					fontFamily: _ctx.elementInfo.defaultFontName,
					writingMode: _ctx.elementInfo.vertical ? 'vertical-rl' : 'horizontal-tb'
				})
      }, [
        _createVNode(_component_ElementOutline, {
          width: _ctx.elementInfo.width,
          height: _ctx.elementInfo.height,
          outline: _ctx.elementInfo.outline
        }, null, 8, ["width", "height", "outline"]),
        _createElementVNode("div", {
          class: "text ProseMirror-static",
          innerHTML: _ctx.content
        }, null, 8, _hoisted_1)
      ], 4)
    ], 4)
  ], 4))
}