import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({
			top: _ctx.elfElementInfo.top + 'px',
			left: _ctx.elfElementInfo.left + 'px',
			width: _ctx.elfElementInfo.width + 'px',
			height: _ctx.elfElementInfo.height + 'px'
		}),
    class: "base-element-elf"
  }, [
    _createElementVNode("div", {
      class: "element-content",
      onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleClickBaseElfElement && _ctx.handleClickBaseElfElement(...args)), ["stop"]))
    }, [
      (_ctx.isPopulate && _ctx.currentElfComponent && _ctx.canRender)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentElfComponent), {
            key: _ctx.elfElementInfo.id,
            ref: "component",
            elementInfo: _ctx.elfElementInfo,
            inScreening: _ctx.inScreening,
            previewSign: _ctx.previewSign,
            isEdit: false
          }, null, 8, ["elementInfo", "inScreening", "previewSign"]))
        : _createCommentVNode("", true)
    ])
  ], 4))
}