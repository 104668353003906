
import { defineComponent } from 'vue'
export default defineComponent({
	name: 'button-dialog',
	setup(props, { emit }) {
		const close = () => {
			emit('onCancel')
		}
		const open = () => {
			emit('onOk')
		}
		const closeDialog = () => {
			emit('close')
		}
		return {
			close,
			open,
			closeDialog
		}
	}
})
