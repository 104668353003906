
import { defineComponent, PropType } from 'vue'
import { PPTLinkButtonElement } from '@/types/slides'

export default defineComponent({
	name: 'base-element-link-button',
	props: {
		elementInfo: {
			type: Object as PropType<PPTLinkButtonElement>,
			required: true
		}
	}
})
