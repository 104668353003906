import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createBlock as _createBlock, vShow as _vShow, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-7dccd09b")
const _hoisted_1 = {
  key: 0,
  class: "handler"
}
const _hoisted_2 = ["onMousedown"]
const _hoisted_3 = ["width"]
const _hoisted_4 = ["rowspan", "colspan", "data-cell-index", "onMousedown", "onMouseenter"]
const _hoisted_5 = ["innerHTML"]
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CustomTextarea = _resolveComponent("CustomTextarea")!
  const _directive_contextmenu = _resolveDirective("contextmenu")!

  return (_openBlock(), _createElementBlock("div", {
    class: "editable-table",
    style: _normalizeStyle({ width: _ctx.totalWidth + 'px' })
  }, [
    (_ctx.editable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dragLinePosition, (pos, index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "drag-line",
              key: index,
              style: _normalizeStyle({
          left: pos + 'px',
        }),
              onMousedown: $event => _ctx.handleMousedownColHandler($event, index)
            }, null, 44, _hoisted_2))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("table", {
      class: _normalizeClass({
        'theme': _ctx.theme,
        'row-header': _ctx.theme?.rowHeader,
        'row-footer': _ctx.theme?.rowFooter,
        'col-header': _ctx.theme?.colHeader,
        'col-footer': _ctx.theme?.colFooter,
      }),
      style: _normalizeStyle(`--themeColor: ${_ctx.theme?.color}; --subThemeColor1: ${_ctx.subThemeColor[0]}; --subThemeColor2: ${_ctx.subThemeColor[1]}`)
    }, [
      _createElementVNode("colgroup", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colSizeList, (width, index) => {
          return (_openBlock(), _createElementBlock("col", {
            span: "1",
            key: index,
            width: width
          }, null, 8, _hoisted_3))
        }), 128))
      ]),
      _createElementVNode("tbody", null, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableCells, (rowCells, rowIndex) => {
          return (_openBlock(), _createElementBlock("tr", { key: rowIndex }, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(rowCells, (cell, colIndex) => {
              return _withDirectives((_openBlock(), _createElementBlock("td", {
                class: _normalizeClass(["cell", {
              'selected': _ctx.selectedCells.includes(`${rowIndex}_${colIndex}`) && _ctx.selectedCells.length > 1,
              'active': _ctx.activedCell === `${rowIndex}_${colIndex}`,
            }]),
                style: _normalizeStyle({
              borderStyle: _ctx.outline.style,
              borderColor: _ctx.outline.color,
              borderWidth: _ctx.outline.width + 'px',
              ..._ctx.getTextStyle(cell.style),
            }),
                key: cell.id,
                rowspan: cell.rowspan,
                colspan: cell.colspan,
                "data-cell-index": `${rowIndex}_${colIndex}`,
                onMousedown: $event => _ctx.handleCellMousedown($event, rowIndex, colIndex),
                onMouseenter: ($event: any) => (_ctx.handleCellMouseenter(rowIndex, colIndex))
              }, [
                (_ctx.activedCell === `${rowIndex}_${colIndex}`)
                  ? (_openBlock(), _createBlock(_component_CustomTextarea, {
                      key: 0,
                      class: _normalizeClass(["cell-text", { 'active': _ctx.activedCell === `${rowIndex}_${colIndex}` }]),
                      contenteditable: "plaintext-only",
                      value: cell.text,
                      onUpdateValue: value => _ctx.handleInput(value, rowIndex, colIndex),
                      onInsertExcelData: value => _ctx.insertExcelData(value, rowIndex, colIndex)
                    }, null, 8, ["class", "value", "onUpdateValue", "onInsertExcelData"]))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: "cell-text",
                      innerHTML: _ctx.formatText(cell.text)
                    }, null, 8, _hoisted_5))
              ], 46, _hoisted_4)), [
                [_vShow, !_ctx.hideCells.includes(`${rowIndex}_${colIndex}`)],
                [_directive_contextmenu, el => _ctx.contextmenus(el)]
              ])
            }), 128))
          ]))
        }), 128))
      ])
    ], 6)
  ], 4))
}