import { useStore } from '@/store'
import { computed } from 'vue'
import { PPTElfSongElement } from '@/types/slides'
let elementInfo: PPTElfSongElement
export default () => {
	const store = useStore()
	const token = computed(() => store.state.token)
	/**
	 * 上传录音
	 */
	const uploadRecording = (data: any) => {
		return new Promise<void>((resolve, reject) => {
			fetch(`${process.env.VUE_APP_API_HOST}/teacher/records`, {
				method: 'post',
				headers: {
					'Content-Type': 'application/json',
					Authorization: `Bearer ${token.value}`
				},
				body: JSON.stringify(data)
			})
				.then((res) => {
					if (res.status >= 200 && res.status < 300) {
						resolve()
					} else {
						reject('')
					}
				})
				.catch((err) => {
					reject(err?.message)
				})
		})
	}

	/**
	 * 获取elementInfo
	 */
	const getElementInfo = () => {
		return elementInfo
	}
	const setElementInfo = (info: PPTElfSongElement) => {
		elementInfo = info
	}
	return {
		uploadRecording,
		setElementInfo,
		getElementInfo
	}
}
