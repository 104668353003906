import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, withScopeId as _withScopeId } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ElementOutline = _resolveComponent("ElementOutline")!
  const _component_Chart = _resolveComponent("Chart")!

  return (_openBlock(), _createElementBlock("div", {
    class: "screen-element-chart",
    style: _normalizeStyle({
      top: _ctx.elementInfo.top + 'px',
      left: _ctx.elementInfo.left + 'px',
      width: _ctx.elementInfo.width + 'px',
      height: _ctx.elementInfo.height + 'px',
    })
  }, [
    _createElementVNode("div", {
      class: "element-content",
      style: _normalizeStyle({
        backgroundColor: _ctx.elementInfo.fill,
      })
    }, [
      _createVNode(_component_ElementOutline, {
        width: _ctx.elementInfo.width,
        height: _ctx.elementInfo.height,
        outline: _ctx.elementInfo.outline
      }, null, 8, ["width", "height", "outline"]),
      _createVNode(_component_Chart, {
        width: _ctx.elementInfo.width,
        height: _ctx.elementInfo.height,
        type: _ctx.elementInfo.chartType,
        data: _ctx.elementInfo.data,
        options: _ctx.elementInfo.options,
        themeColor: _ctx.elementInfo.themeColor,
        gridColor: _ctx.elementInfo.gridColor
      }, null, 8, ["width", "height", "type", "data", "options", "themeColor", "gridColor"])
    ], 4)
  ], 4))
}