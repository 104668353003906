import { ElfElementTypes } from '@/types/slides'
import mainComponent from './main.vue'
// import toolbarComponent from './toolbar.vue'
import toolbarComponent from './toolbar.vue'


export default {
  initData: {
    subtype: ElfElementTypes.ENSEMBLE_PRACTICE,
    width: 309,
    height: 95,
  },
  mainComponent,
  toolbarComponent
}