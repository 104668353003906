
const DEFAULT_BACKGROUND = require('@/assets/images/enjoy-song/default_background.jpg')
import { computed, ref, defineComponent, onMounted, onBeforeUnmount } from 'vue'
import emitter, { EmitterEvents } from '@/utils/emitter'
export default defineComponent({
	name: 'SlideShow',
	props: ['imageList'],
	setup(props) {
		const images = ref(props.imageList || [])
		const currentIndex = ref(0)

		//  显示图片
		const currentImage = computed(() => {
			return images.value[currentIndex.value] || DEFAULT_BACKGROUND
		})

		//  上一页
		const onPrevPage = () => {
			currentIndex.value--
		}
		//  下一页
		const onNextPage = () => {
			currentIndex.value++
		}

		//  切换按钮显示
		const isFirst = computed(() => {
			if (currentIndex.value === 0) {
				return true
			}
			return false
		})
		const isLast = computed(() => {
			if (currentIndex.value === props.imageList.length - 1) {
				return true
			}
			return false
		})
		const isDefaultBg = computed(() => props.imageList.length === 0)

		const setCurrentIndexTo0 = () => {
			currentIndex.value = 0
		}
		onMounted(() => {
			emitter.on(EmitterEvents.SWITCH_SLIDE_PAGE, setCurrentIndexTo0)
		})
		onBeforeUnmount(() => {
			emitter.off(EmitterEvents.SWITCH_SLIDE_PAGE, setCurrentIndexTo0)
		})

		return {
			currentImage,
			onPrevPage,
			onNextPage,
			isFirst,
			isLast,
			isDefaultBg
		}
	}
})
