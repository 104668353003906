import { ElfElementTypes } from '@/types/slides'
import mainComponent from './main.vue'
import toolbarComponent from './toolbar.vue'


export default {
  initData: {
    subtype: ElfElementTypes.CAROUSE_IMAGE,
    width: -1,
    height: -1,
  },
  mainComponent,
  toolbarComponent
}