
import _ from 'lodash'
import usePopulateElem from '@/hooks/usePopulateElem'
import { ElfElementTypes, PPTElfElement } from '@/types/slides'
import {
	computed,
	defineComponent,
	inject,
	PropType,
	ref,
	toRaw,
	watch
} from 'vue'
import { findElfByType } from './register'
import useSingleElement from '@/hooks/useSingleElement'

export default defineComponent({
	name: 'base-element-elf',
	props: {
		elementInfo: {
			type: Object as PropType<PPTElfElement>,
			required: true
		},
		inScreening: {
			type: Boolean,
			required: false
		}
	},
	setup(props) {
		const { canRender: checkCanRender } = useSingleElement([
			ElfElementTypes.SING_PLAY,
			ElfElementTypes.QUESTIONS_BANK,
			ElfElementTypes.PK_QUESTIONS,
		])
		const currentElfComponent = computed(() => {
			const config = findElfByType(props.elementInfo.subtype)
			return config?.mainComponent
		})

		const isPopulate = ref(false)
		const { populateElem } = usePopulateElem()
		const elfElementInfo = ref(toRaw(props.elementInfo))

		const handleClickScreenElement: any = inject('handleClickScreenElement')

		watch(
			() => props.elementInfo,
			(newVal) => {
				isPopulate.value = false
				if (newVal.subtype === 'elf-sing-play' && !(newVal as any)?.meta) {
					populateElem(newVal).then((_elfElementInfo: any) => {
						_.merge(elfElementInfo.value, _elfElementInfo)
						isPopulate.value = true
					})
				} else {
					elfElementInfo.value = toRaw(newVal)
					isPopulate.value = true
				}
			}
		)
		populateElem(elfElementInfo.value).then((_elfElementInfo: any) => {
			_.merge(elfElementInfo.value, _elfElementInfo)
			isPopulate.value = true
		})

		const canRender = computed(() => {
			return checkCanRender(props.elementInfo.id, props.elementInfo.subtype)
		})
		const component = ref()
		const doAction = () => {
			component.value?.doAction?.call()
		}

		const handleClickBaseElfElement = (e: Event) => {
			handleClickScreenElement && handleClickScreenElement(e)
		}

		return {
			currentElfComponent,
			component,
			doAction,
			elfElementInfo,
			isPopulate,
			canRender,
			handleClickBaseElfElement
		}
	}
})
