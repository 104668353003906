
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { PPTLineElement } from '@/types/slides'
import { getLineElementPath } from '@/utils/element'
import { ContextmenuItem } from '@/components/Contextmenu/types'
import useElementShadow from '@/views/components/element/hooks/useElementShadow'

import LinePointMarker from './LinePointMarker.vue'
import { getCurveLinePath } from '@/utils/drawLine'
import { MutationTypes, useStore } from '@/store'
import _ from 'lodash'

export default defineComponent({
	name: 'editable-element-shape',
	components: {
		LinePointMarker
	},
	props: {
		elementInfo: {
			type: Object as PropType<PPTLineElement>,
			required: true
		},
		selectElement: {
			type: Function as PropType<
				(e: MouseEvent, element: PPTLineElement, canMove?: boolean) => void
			>,
			required: true
		},
		contextmenus: {
			type: Function as PropType<() => ContextmenuItem[]>
		}
	},
	setup(props) {
		const store = useStore()
		const handleSelectElement = (e: MouseEvent) => {
			if (props.elementInfo.lock) return
			e.stopPropagation()

			props.selectElement(e, props.elementInfo)
		}

		const svgPathRef = ref()

		const shadow = computed(() => props.elementInfo.shadow)
		const isCustom = computed(() => !!props.elementInfo?.customPath)
		const { shadowStyle } = useElementShadow(shadow)

		const svgWidth = computed(() => {
			const width = Math.abs(
				props.elementInfo.start[0] - props.elementInfo.end[0]
			)
			return width < 24 ? 24 : width
		})
		const svgHeight = computed(() => {
			const height = Math.abs(
				props.elementInfo.start[1] - props.elementInfo.end[1]
			)
			return height < 24 ? 24 : height
		})

		const lineDashArray = computed(() =>
			props.elementInfo.style === 'dashed' ? '10 6' : '0 0'
		)

		const path = computed(() => {
			if (props.elementInfo?.customPath) {
				return props.elementInfo?.customPath
			}
			return getLineElementPath(props.elementInfo)
		})

		let computedCustomPath = ''

		/** 计算svg路径范围 */
		const computedSvgBBox = () => {
			if (svgPathRef.value && props.elementInfo.custom) {
				const { x, y, width, height } = svgPathRef.value.getBBox() || {
					x: 0,
					y: 0,
					width: 0,
					height: 0
				}
				if (
					x < 0 ||
					y < 0 ||
					height > props.elementInfo.end[1] || height + 8 < props.elementInfo.end[1] ||
					width > props.elementInfo.end[0] || width + 8 < props.elementInfo.end[0]
				) {
					const _svgPoint = props.elementInfo.custom.map(
						(point: { x: number; y: number }) => {
							return {
								x: point.x - x,
								y: point.y - y
							}
						}
					)

					const path = getCurveLinePath(_svgPoint)
					const newLineElement: PPTLineElement = {
						...props.elementInfo,
						end: [width, height],
						customPath: path,
						path,
						custom: _svgPoint,
						left: props.elementInfo.left + x,
						top: props.elementInfo.top + y
					}
					computedCustomPath = path
					store.commit(MutationTypes.UPDATE_ELEMENT, {id: props.elementInfo.id, props: newLineElement})

				}
			}
		}

		watch(
			() => props.elementInfo?.customPath,
			_.debounce(() => {
				if (props.elementInfo?.customPath === computedCustomPath) {
					return
				}
				computedSvgBBox()
			}, 200, {leading: false, trailing: true})
		)

		return {
			handleSelectElement,
			shadowStyle,
			svgWidth,
			svgHeight,
			lineDashArray,
			path,
			isCustom,
			svgPathRef
		}
	}
})
