import { ElfElementTypes } from '@/types/slides'
import mainComponent from './main.vue'

export default {
  initData: {
    subtype: ElfElementTypes.NONE,
    width: -1,
    height: -1,
  },
  delegateFor: [],
  mainComponent,
}